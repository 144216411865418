import { useCallback, useEffect, useState } from "react";
import {
  AppstoreOutlined,
  BarsOutlined,
  CloseOutlined,
  FilterOutlined,
  ReloadOutlined,
  SearchOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  DatePicker,
  Select,
  Input,
  Avatar,
  Row,
  Col,
  Tooltip,
  Button,
  ConfigProvider,
  TimeRangePickerProps,
  Segmented,
} from "antd";
import BooksService from "../../Services/Books";

import { getEllipsisText, isUserAdmin } from "../../utils/Util";
import "../../common/Toothbar.css";
import Adduser from "./Adduser";
import { userOptions as UD } from "../../constants/commonConstants";
import { useSelector } from "react-redux";
import {
  projectData,
  setProjectData as setProjectReduxData,
} from "../../store/slices/project-slice";
import { userData } from "../../store/slices/user-slice";
import { useDispatch } from "react-redux";
import AssigneeAvatar from "./AssigneeAvatar";
import dayjs from "dayjs";
import "../../common/common.css";
import { useLocation } from "react-router-dom";
import { refreshBacklog, refreshBoard } from "../../store/slices/refresh-slice";

const { Option } = Select;

const Toothbar = ({
  page,
  setTaskData,
  taskData,
  setTitleImg,
  setDefaultUser,
  projectname,
  handleAvatarClick,
  width,
  setWidth,
  setFormData,
  setAssigne,
  showLoading,
  projectassigneedata,
  setProjectId,
  localproject,
  setlocalporject,
  todo,
  isLoading,
  userfilterpid,
  setFromdate,
  setTodate,
  setIsLoading,
  setPriority,
  selectedAvatarId,
  setSelectedAvatarId,
  setReloadKey,
  boardViewMode,
  handleViewModeChange,
  setTaskSearchFilter,
  setRefreshData,
  searchTaskData,
  setSearchTaskData
}: any) => {
  const dispatch = useDispatch();
  const [projectsData, setProjectData] = useState<any[]>([]);
  const [projectassigneearr, setprojectassigneearr] = useState<any[]>([]);
  const [projectdataassignee, setprojectdataassignee] = useState<any[]>([]);
  const [isOpen2, setIsOpen2] = useState(false);
  const openModal2 = useCallback(() => setIsOpen2(true), []);
  const closeModal2 = useCallback(() => setIsOpen2(false), []);
  const [assigneeavatardata, setassigneeavatardata] = useState<any[]>([]);
  const [changeassignee, setchangeassignee] = useState(false);
  const user = useSelector(userData);
  const project = useSelector(projectData);
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const isBacklogPage = location.pathname === "/backlog";
  const [widthofgrid, setWidthofgrid] = useState(window.innerWidth);
  const fetchassignee = async () => {
    try {
      const res = await BooksService.getUserByProjectId(project?.id);
      // console.log("res.result", res.result);
      setassigneeavatardata(res?.result[0].assignee);

      setprojectassigneearr(
        res.result[0].assignee.map((a: any) => {
          return {
            value: a?.id,
            label: a?.name,
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (page !== "viewall") {
      let arr = projectname.split(" ");
      if (arr?.length > 1) {
        setTitleImg((arr[0].charAt(0) + arr[1].charAt(0)).toUpperCase());
      } else {
        setTitleImg((arr[0].charAt(0) + arr[0].charAt(1)).toUpperCase());
      }
      fetchassignee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectname, changeassignee, project.id]);

  const fetchProjects = async () => {
    try {
      const res = await BooksService.getProjectByUser(user?.id);
      const userdatatype = res?.result?.map((item: any) => ({
        label: item.name,
        value: item?.id,
        ...item,
      }));
      setProjectData(userdatatype);
      setprojectdataassignee(res?.result);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (value: string) => {
    // console.log("search:", value);
  };
  const priorityOptions = [
    { label: "All", value: -1 },
    { label: "High", value: 2 },
    { label: "Medium", value: 1 },
    { label: "Low", value: 0 },
  ];
  const [selectedProject, setSelectedProject] = useState({
    value: project?.id,
    label: project?.name,
  });

  const [searchTerm, setSearchTerm] = useState("");
  // priority Search
  const handleDateChange = (dates: any) => {
    if (dates === null) {
      console.log("No dates selected, showing total data.");
      setFromdate(null);
      setTodate(null);
    } else if (dates && dates.length === 2) {
      const fromDate = dayjs(dates[0]);
      const toDate = dayjs(dates[1]);
      setFromdate(fromDate?.format("DD/MM/YYYY"));
      setTodate(toDate?.format("DD/MM/YYYY"));
      console.log("From Date:", fromDate.format("DD/MM/YYYY"));
      console.log("To Date:", toDate.format("DD/MM/YYYY"));
    }
  };
  // const handleClick = () => {
  //   setIsLoading(true);

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // };
  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().add(0, "d"), dayjs()] },
    { label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs()] },
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];
  const [showInput, setShowInput] = useState(false);

  // const handleButtonClick = () => {
  //   setShowInput(!showInput);
  // };
  const handleButtonClick = () => {
    setShowInput(true); // Show input when Filter button is clicked
  };

  const handleCloseClick = () => {
    setShowInput(false); // Hide input when Close button is clicked
  };
  const options = [
    {
      value: "List",
      icon: isBacklogPage ? (
        <BarsOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
      ) : (
        <BarsOutlined />
      ),
    },
    // {
    //   value: "Kanban",
    //   icon: isBacklogPage ? (
    //     <AppstoreOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
    //   ) : (
    //     <AppstoreOutlined />
    //   ),
    // },
  ];

  useEffect(() => {
    const handleResize = () => setWidthofgrid(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const searchData = ()=>{
    console.log("searchTermsearchTermsearchTerm = ",searchTerm);
    let searchContent = searchTerm?.toLowerCase()
    let temp :any = {
      ...taskData
    }
    for(let key in temp)
    {
      temp[key] =  searchTaskData[key]?.filter((x:any)=>x?.Title?.toLowerCase()?.includes(searchContent)||x?.UKey?.toLowerCase()?.includes(searchContent))
    }
    if(!!searchTerm)
    {
      setTaskData({...temp})
    }
    else
    setTaskData({...searchTaskData})
    console.log("temptemptemp = ",temp);
  }
  useEffect(()=>{
searchData()
  },[searchTerm])
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorTextPlaceholder: "#95a5a6",
              fontSize: 14,
              colorPrimaryHover: "none",
              fontSizeIcon: 11,
              paddingSM: 0,
              paddingXS: 0,
              paddingXXS: 0,
              lineWidth: 1,
              borderRadius: 0,
              borderRadiusLG: 0,
              borderRadiusSM: 0,
              borderRadiusXS: 0,
              controlPaddingHorizontal: 12,
              controlPaddingHorizontalSM: 12,
              controlHeight: 28,
              controlHeightLG: 27,
              algorithm: true,
              fontWeightStrong: 600,
            },
            DatePicker: {
              controlHeight: 27,
              controlHeightLG: 27,

              hoverBorderColor: "#dee2e6",

              activeBorderColor: "#dee2e6",
            },
            Input: {
              controlHeight: 27,
              fontSizeIcon: 12,
              controlHeightLG: 27,
              inputFontSize: 14,
              colorTextPlaceholder: "#d9d9d9",
            },
          },
        }}
      >
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col span={5}>
            <Select
              className="capTask-custom-select"
              style={{ width: "200px" }}
              placeholder="Select a Project"
              labelInValue
              options={projectsData}
              showSearch
              onChange={(v) => {
                setSelectedProject(v);
                dispatch(
                  setProjectReduxData({
                    id: v?.value,
                    name: v?.label,
                  })
                );
              }}
              value={selectedProject}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input?.toLowerCase())
              }
            />
          </Col>

          <Col className="mr-6">
            {/* {isUserAdmin() && (
              <Tooltip title="Add user">
                <span style={{ cursor: "pointer" }} onClick={openModal2}>
                  <Avatar
                    size={32}
                    style={{
                      backgroundColor: "rgb(240, 242, 244)",
                      // margin: "6px 0px",
                      cursor: "pointer",
                      // width: "29px",
                      // height: "29px",
                      fontSize: "16px",
                    }}
                    // className="ml-4"
                    src={
                      <UserAddOutlined
                        style={{ color: "#b9bfc9", cursor: "pointer" }}
                      />
                    }
                  />
                </span>
              </Tooltip>
            )} */}
            <Input
              className="custom-input ml-6"
              style={{ border: "1px solid #d9d9d9", width: "190px" }}
              suffix={
                <SearchOutlined
                  style={{
                    color: "rgba(0, 0, 0, 0.45)",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
              }

              placeholder="Search..."
              allowClear
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e?.target?.value)
              }}
            />

            <Button
              className="custom-button"
              style={{
                height: 27,
                margin: 10,
                borderColor: "#d3e3ff",
                borderRadius: "4px",
                backgroundColor: "#f3f4fa",
              }}
              icon={<FilterOutlined style={{ color: "#377dff" }} />}
              onClick={handleButtonClick}
            />

            <Segmented
              className="custom-segmented"
              style={{ minHeight: "27px", lineHeight: "27px" }}
              options={options}
              value={boardViewMode === "list" ? "List" : "Kanban"}
              onChange={handleViewModeChange}
              disabled={widthofgrid > 1220 || widthofgrid < 768 ? false : true}
            />

            <Button
              className="custom-button"
              onClick={() => {
                dispatch(refreshBoard());
                dispatch(refreshBacklog());
              }}
              style={{ height: 27, margin: 10 }}
              icon={
                <ReloadOutlined
                  style={{ color: "rgb(61, 153, 37)", fontSize: "14px" }}
                />
              }
            />
          </Col>
        </Row>

        {showInput && (
          <Row
            justify={"space-between"}
            align={"middle"}
            gutter={[15, 0]}
            style={{
              backgroundColor: "rgba(247, 248, 255, 0.5)",
              marginLeft: " 9.5px;",
              marginRight: "36.5px",
              borderRadius: 4,
            }}
          >
            <Col span={22} md={22}>
              <Select
                className="capTask-custom-select mr-3"
                style={{ width: 180 }}
                defaultValue={UD[0]}
                onChange={(item) => setDefaultUser(item)}
                placeholder="Please Select Users"
                options={UD}
                showSearch
                onSearch={onSearch}
                optionFilterProp="label"
              />

              <Select
                className="capTask-custom-select mr-3"
                style={{ width: 140 }}
                // defaultValue={priorityOptions[0]}
                onChange={(item: any) => {
                  // console.log("Selected", item);
                  setPriority(item, "priority");
                }}
                placeholder="Priority"
                options={priorityOptions}
                showSearch
              />

              <RangePicker
                className="mr-3"
                presets={rangePresets}
                onChange={handleDateChange}
                style={{
                  height: "27px",
                  width: " 240px",
                }}
              />

              <Select
                className="capTask-custom-select"
                style={{ width: 170, margin: "4px 0px" }}
                placeholder="Select an Assignee"
                // value={selectedAvatarId}
                showSearch
                onSearch={onSearch}
                optionFilterProp="label"
                allowClear
                onSelect={(value) => {
                  console.log("value =", value);

                  handleAvatarClick(value);
                }}
                onClear={() => {
                  handleAvatarClick(undefined);
                }}
              >
                {assigneeavatardata.map((assignee: any, index: number) => (
                  <Option
                    label={assignee?.name}
                    key={assignee?.id}
                    value={assignee?.id}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <AssigneeAvatar
                        size={23}
                        assignee={assignee}
                        index={index}
                      />
                      <span style={{ marginLeft: 8 }}>
                        {getEllipsisText(assignee?.name, 10)}
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Col>

            <Col>
              <Button
                type="text"
                style={{
                  backgroundColor: "#eef3fa",
                  borderRadius: "50%",
                  height: "25px",
                  width: "25px",
                  margin: 3,
                }}
                icon={
                  <ReloadOutlined
                    style={{ color: "rgb(211, 18, 18)", fontSize: "13px" }}
                  />
                }
                onClick={() => {
                  dispatch(refreshBoard());
                  dispatch(refreshBacklog());
                }}
              />

              <Button
                style={{
                  backgroundColor: "#eef3fa",
                  borderRadius: "50%",
                  height: "25px",
                  width: "25px",
                  margin: 5,
                }}
                // shape="circle"
                type="text"
                icon={<CloseOutlined className="CloseOutlinedIcon" />}
                onClick={handleCloseClick}
              />
            </Col>
          </Row>
        )}

        {isOpen2 && (
          <Adduser
            projectassigneearray={projectassigneearr}
            changeassignee={changeassignee}
            setchangeassignee={setchangeassignee}
            fetchassignee={fetchassignee}
            projectdataassignee={projectdataassignee}
            isOpen2={isOpen2}
            openModal2={openModal2}
            closeModal2={closeModal2}
          />
        )}
      </ConfigProvider>
    </>
  );
};
export default Toothbar;
