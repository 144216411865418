import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Row, Col, Alert, notification, Divider, Typography } from "antd";
 import "./Login.css";
import Routes from "../Services/Endpoints";
import BooksService from "../Services/Books";
import { ApiUtility } from "../Services/ApiUtility";
import Loader from "../components/commoncomponents/Loader";
import { UserOutlined } from "@ant-design/icons";

interface LoginHandleType {
  email: string;
  password: string;
  remember: boolean;
}

const Forgotpasswordalert = () => {
  return (
    <div>
      <div style={{ padding: "5px" }}>
        <p style={{ fontSize: "15px" }}>
          Incorrect email address / or password
        </p>
        <span style={{ fontSize: "15px" }}>Do you need help</span>
        <Link to="/recovery">
          logging in
        </Link>
      </div>
    </div>
  );
};

const Forgotpassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  const [borderColor, setBorderColor] = useState(false);

  const HandleSubmit = async (data: any) => {
    console.log(data);
    setIsLoading(true);
    try {
      let response: any = await ApiUtility.get(
        Routes.userRoute +
        "/email?" +
        new URLSearchParams({ Email: data?.email }).toString()
      );

      if (response.result) {
        let res = await BooksService.sendmail({ email: data?.email });
        if (res.result) {
          notification.success({
            message: "Success",
            description: "Email sent successfully",
            placement: "bottomLeft",
          });
        } else if (res.errors) {
          notification.error({
            message: "Error",
            description: res.message,
            placement: "bottomLeft",
          });
        }
      } else {
        notification.error({
          message: "Error",
          description: "Email not available",
          placement: "bottomLeft",
        });
      }
    } catch (err: any) {
      notification.error({
        message: "Error",
        description: err.message || "An error occurred",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };
  window.addEventListener("resize", () => setWidth(window.innerWidth));

  return (
    <>
      <Loader loading={isLoading} />
      <Row>
        {width >= 1024 ? (
          <Col
            span={13}
            style={{
              backgroundColor: "#F5F6F9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <div
              style={{ textAlign: "center", justifyContent: "center" }}
              className="pb-4"
            >
              <img
                src={process.env.PUBLIC_URL + "/tasklogin1.svg"}
                alt="tasklogin1"
              />
              <h2
                className="pb-2 pt-5"
                style={{
                  color: "#0E51D3",
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                }}
              >
                We're here to manage your work
              </h2>
              <h4
                style={{
                  color: "#253148",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                We're here for your Work Management & Time Management with
                CapsiTask
              </h4>
            </div>
          </Col>
        ) : null}
        <Col
          span={width >= 1024 ? 10 : 24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form
            onFinish={HandleSubmit}
            style={{ width: "100%", maxWidth: 353 }}
            layout="vertical"
          >
            <Form.Item style={{ paddingBottom: "32px" }}>
              <img
                style={{ width: "163px", cursor: "pointer" }}
                src={process.env.PUBLIC_URL + "/CapsiTask.png"}
                alt=""
              />
              <Divider style={{ color: "#dee2e6 " }} />
              <Typography.Text
                style={{
                  color: "rgb(34, 59, 149)",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                Recover Password
              </Typography.Text>
            </Form.Item>
            {/* {isincorrect && <Forgotpasswordalert />} */}
            {/* <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                fontFamily: "Open Sans",
                color: "#253148",
                paddingBottom: "40px",
                textAlign: "center",
              }}
            >
              Forgot Password
            </p> */}
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter your email!" }]}
            >
              {/* <Input
                type="email"
                placeholder="Email"
                style={{
                  height: 50,
                  borderRadius: 10,
                  borderColor: borderColor ? "#0E51D3" : "#B9C4D6",
                  // padding: "13px 15px",
                  backgroundColor: "#E9F1FF",
                }}
                onFocus={() => setBorderColor(true)}
                onBlur={() => setBorderColor(false)}
              /> */}
              <Input
                placeholder="Enter Email"
                variant="borderless"
                style={{
                  borderRadius: 0,
                  backgroundColor: "#f9faff",
                  height: 35,
                }}
                suffix={<UserOutlined />}
              />
            </Form.Item>
            <Link className="forgotButton" to="/login">
              {" "}
              <Typography.Link>Back to Login</Typography.Link>
            </Link>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                className="mt-5"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                // block
                style={
                  {
                    // height: "40px",
                    // display: "flex",
                    // alignItems: "center",
                    // backgroundColor: "#0E51D3",
                  }
                }
              >
                Submit
              </Button>
            </Form.Item>
            <Divider style={{ color: "#dee2e6 " }} />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Forgotpassword;

