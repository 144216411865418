// import { useEffect, useMemo, useState } from "react";
// import { Modal, Button, Spin, notification, TreeSelect, Select } from "antd";
// import { CheckCircleOutlined } from "@ant-design/icons";
// import HTTPSCalls from "../../Services/HTTPCalls";
// // import BooksService from "../../Services/Books";
// import { useSelector } from "react-redux";
// import { projectData } from "../../store/slices/project-slice";
// import { AddEditApis } from "../../Services/RQTaskService";

// const Adduser = ({
//   isOpen2,
//   closeModal2,
//   changeassignee,
//   setchangeassignee,
//   projectassigneearray,
//   fetchassignee,
//   projectdataassignee,
// }: any) => {
//   const {useUser} = AddEditApis
//   const project = useSelector(projectData);

//   const [assigneearray, setassigneearray] = useState<any[]>(
//     projectassigneearray || []
//   );
//   const [loading, setloading] = useState(false);
//   const [searchFilter, setSearchFilter] = useState<{
//     user: {
//       search: undefined;
//     };
//   }>({
//     user: {
//       search: undefined,
//     },
//   });

//   // const { data: userData } = useProjectuser({ length: 1500 });
//   const { data: userDataList, isPending: isUserPending } = useUser(
//     searchFilter?.user
//   );
//   // const user = useSelector(userData);
//   // const userListDataSource = useMemo(() => {
//   //   return userData?.result?.map((item: any) => ({
//   //     label: item?.name,
//   //     value: item?.id,
//   //   }));
//   // }, [userData]);
//   const reporterOptions = useMemo(() => {
//     return userDataList?.result?.items?.map((itm: any) => itm) || [];
//   }, [userDataList]);
//   const handlesubmit = async () => {
//     setloading(true);
//     try {
//       await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.addEdit, {
//         id: project?.id,
//         name: projectdata?.name,
//         assigneeIds: assigneearray,
//         type: 1,
//         //  company: "63a959074e041fdc2ae91ce8",
//         //  alias: projectdata?.alias,
//         // description: projectdata?.description,
//         // imageThumbPath: projectdata?.imageThumbPath,
//         // imagePath: projectdata?.imagePath,
//       });
//       notification.success({
//         message: "Success",
//         description: "Assignee updated successfully",
//         placement: "bottomLeft",
//         icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
//       });
//       closeModal2();
//       setchangeassignee(!changeassignee);
//       fetchassignee();
//     } catch (error) {
//       console.error("Failed to update assignee:", error);
//     } finally {
//       setloading(false);
//     }
//   };

//   useEffect(() => {
//     setprojectdata(
//       projectdataassignee?.find((item: any) => item?.id === project?.id)
//     );
//     // fetchalluser();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isOpen2, projectassigneearray, projectdataassignee]);
//   return (
//     <Modal
//       open={isOpen2}
//       title="Add Assignee"
//       onCancel={closeModal2}
//       loading={loading}
//       footer={[
//         <Button key="cancel" onClick={closeModal2}>
//           Cancel
//         </Button>,
//         <Button
//           key="submit"
//           type="primary"
//           onClick={handlesubmit}
//           loading={loading}
//         >
//           Add
//         </Button>,
//       ]}
//     >
//       {loading && <Spin size="large" className="loading-spinner" />}
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <div style={{ width: "100%" }}>
//           {/* <Select
//             mode="multiple"
//             placeholder="Choose Label"
//             options={alluseroption}
//             // defaultValue={projectassigneearray.map((item: any) => item.value)}
//             value={assigneearray}
//             onChange={(value) => setassigneearray(value)}
//             style={{ width: "100%" }}
//             optionFilterProp="label"
//             showSearch
//           >
//             {alluseroption.map((option: any) => (
//               <Option key={option.value} value={option.value}>
//                 {option.label}
//               </Option>
//             ))}
//           </Select> */}
//           {/* <TreeSelect
//             className="treeselectadduser"
//             multiple
//             treeData={userListDataSource}
//             value={assigneearray}
//             onChange={(value) => setassigneearray(value)}
//             placeholder="Choose Label"
//             style={{ width: "100%" }}
//             treeCheckable
//             treeNodeFilterProp="label"
//             showSearch
//           /> */}
//           <Select
//             labelInValue
//             variant="borderless"
//             className="borderLessInput w100"
//             placeholder="Choose reporter"
//             allowClear

//             options={reporterOptions}
//             // value={searchFilter}
//             loading={isUserPending}
//             searchValue={searchFilter?.user?.search}
//             showSearch
//             onSearch={(value) => {
//               setSearchFilter((pre: any) => ({
//                 ...pre,
//                 user: {
//                   search: value,
//                 },
//               }));
//             }}
//             optionFilterProp="label"
//           />
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default Adduser;
import { useEffect, useMemo, useState } from "react";
import { Modal, Button, Select, Form, Row, message } from "antd";
import { AddEditApis } from "../../Services/RQTaskService";
import React from "react";
import { useProjectuser } from "../../Services/RQMastersService";
import { projectData } from "../../store/slices/project-slice";
import { useSelector } from "react-redux";
const Adduser = ({
  isOpen2,
  closeModal2,
  changeassignee,
  setchangeassignee,
  projectassigneearray,
  fetchassignee,
  projectdataassignee,
  projectassigneearr,
  props,
}: any) => {
  const { useUser } = AddEditApis;
  const project = useSelector(projectData);
  const [projectdata, setprojectdata] = useState<any>({});
  const [form] = Form.useForm();
  const { mutateAsync: addUserproject } = useProjectuser();
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState<{
    user: { search: undefined };
  }>({ user: { search: undefined } });
  const { data: userDataList, isPending: isUserPending } = useUser(
    searchFilter?.user
  );
  const handleError = (error: any) => {
    // Handle error
  };

  const reporterOptions = useMemo(() => {
    return userDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [userDataList]);
  useEffect(() => {
    form?.setFieldsValue(props?.data);
  }, []);
  // const handlesubmit = async (values: any) => {
  //   setLoading(true);
  //   try {
  //     await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.addEdit, {
  //       id: project?.id,
  //       name: projectdata?.name,
  //       assigneeIds: values.assignees, // Using values from form
  //       type: 1,
  //     });
  //     notification.success({
  //       message: "Success",
  //       description: "Assignee updated successfully",
  //       placement: "bottomLeft",
  //       icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
  //     });
  //     closeModal2();
  //     setchangeassignee(!changeassignee);
  //     fetchassignee();
  //   } catch (error) {
  //     console.error("Failed to update assignee:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        setLoading(true);
        //  console.log(values,"=Valuee");
        let val = {
          ...values,
          id: project?.id,
          name: projectdata?.name,
          assigneeIds: values.assignees,
          type: 1,
        };
        // console.log("val", val);
        let res = await addUserproject(val);
        if (res?.message) {
          console.error("Error =", res?.message);
          message.error("Error-> " + res?.message);
          setLoading(false);
        } else {
          message.success("Project Added Successfully");
          form.resetFields();
          closeModal2();
        }
      })
      .catch(handleError);
  };

  // const  closeModal2 = () => {
  //   form.resetFields();
  //   setOpen(!open);
  //   setTimeout(() => {
  //     props?.onDismiss();
  //   }, 300);
  // };

  // const values = Form.useWatch([], form);
  useEffect(() => {
    if (projectassigneearray?.length > 0) {
      form.setFieldValue("assigneeIds", projectassigneearray);
    } else {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false))
        .finally(() => setLoading(false));
    }
  }, [projectassigneearray, form]);
  useEffect(() => {
    if (submittable) {
      console.log("Submittable is true");
    }
  }, [submittable]);

  return (
    <Modal
      open={isOpen2}
      title="Add Assignee"
      onCancel={closeModal2}
      footer={
        <>
          <Row justify={"end"}>
            <Button
              className="capTask-btn-light btn-m-sm"
              onClick={closeModal2}
            >
              Cancel
            </Button>
            <Button
              className="btn-m-sm"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={!submittable}
            >
              Add
            </Button>
          </Row>
        </>
      }
    >
      <Form
        form={form}
        labelAlign="left"
        layout="horizontal"
        labelCol={{ span: 6 }}
        requiredMark={false}
        initialValues={{ orderNo: 0 }}
        className="DrawerPadding"
      >
        <Form.Item
          name="assigneeIds"
          rules={[{ required: true, message: "Please select an assignee" }]}
        >
          <Select
            maxTagCount={2}
            labelInValue
            mode="multiple"
            variant="borderless"
            className="borderLessInput w100"
            placeholder="Choose reporter"
            allowClear
            options={reporterOptions}
            loading={isUserPending}
            searchValue={searchFilter?.user?.search}
            showSearch
            onSearch={(value) => {
              setSearchFilter((pre: any) => ({
                ...pre,
                user: {
                  search: value,
                },
              }));
            }}
            optionFilterProp="label"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Adduser;
