import * as React from "react";
import { useMemo } from "react";
import { useCallback, useState } from "react";
import SunEditor from "suneditor-react";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FormOutlined,
  HighlightOutlined,
  Html5Outlined,
  JavaScriptOutlined,
  LoadingOutlined,
  TagOutlined,
} from "@ant-design/icons";

import {
  Col,
  Divider,
  Row,
  Typography,
  Button,
  Drawer,
  Space,
  message,
  Spin,
} from "antd";
import { Form, Input, Select, DatePicker, Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../store/slices/user-slice";
import { projectData, setProjectData } from "../store/slices/project-slice";
import { AddEditApis } from "../Services/RQTaskService";
import {
  getLogs,
  getTaskInFormFormatData,
  getTaskPostFormatData,
} from "../utils/Util";
import { IApiResponse } from "../Services/ApiUtility";
import FilesUploader from "./FilesUploader";
import AddEditBranch from "../pages/SidebarBranch/AddEditBranch";
import { dayjs } from "../utils/dayjs";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import AddEditChildTask from "./AddEditChildTask";
import { selectedTaskMethod, setTaskMode } from "../store/slices/task-slice";
import TaskActivity from "./TaskActivity";
import HTTPSCalls from "../Services/HTTPCalls";
import Lodash from "lodash";
import { useSaveTaskLog } from "../Services/RQLogService";
export const TaskStatusOptions = [
  { label: "Backlog", value: 0 },
  { label: "Todo", value: 1 },
  { label: "Need To Discuss", value: 2 },
  { label: "In Progress", value: 3 },
  { label: "UAT", value: 4 },
  { label: "Developed", value: 7 },
  { label: "Duplicate", value: 8 },
  { label: "Testing", value: 5 },
  { label: "Done", value: 6 },
];
interface IAddEditTask {
  disableFields?: {
    projectIds: boolean;
  };
  id?: string | undefined | null;
  parentId?: string | undefined | null; // Drawer call it self for creating new child task
  onDismiss: (rec: boolean) => void;
}
const AddEditTask = ({
  id,
  onDismiss,
  disableFields = undefined,
  parentId = undefined,
}: IAddEditTask) => {
  const [form] = Form.useForm();
  const [childForm] = Form.useForm();
  const user = useSelector(userData);
  const project = useSelector(projectData);
  const taskMode = useSelector(selectedTaskMethod);
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    value: string;
  }>({ label: project?.name, value: project?.id });
  console.log("projectProject = ", project);

  const [currentUKey, setCurrentUKey] = useState<string>("");
  const [previousData, setPreviousData] = useState<any>();
  const [getByIdConvertedData, setGetByIdConvertedData] =
    useState<any>(undefined);
  const [searchFilter, setSearchFilter] = useState<{
    branch: {
      search: undefined;
    };
    issue: {
      search: undefined;
    };
    user: {
      search: undefined;
    };
    assignee: {
      search: undefined;
    };
    project: {
      search: undefined;
    };
    sprint: {
      search: undefined;
    };
    category: {
      search: undefined;
    };
  }>({
    branch: {
      search: undefined,
    },
    issue: {
      search: undefined,
    },
    user: {
      search: undefined,
    },
    assignee: {
      search: undefined,
    },
    project: {
      search: undefined,
    },
    sprint: {
      search: undefined,
    },
    category: {
      search: undefined,
    },
  });

  const {
    useTask,
    useBranch,
    useIssue,
    useProject,
    useUser,
    useAssignee,
    useUkey,
    useGetTask,
    useCategory,
    useSprint,
  } = AddEditApis;

  const { data: taskData, isPending: isGetTask } = useGetTask(id);
  const { mutateAsync: taskLogMutateAsync, isPending: isTaskLogs } =
    useSaveTaskLog();

  const { mutateAsync, isPending: isTaskPost } = useTask();

  const { data: branchDataList, isPending: isBranchPending } = useBranch(
    searchFilter?.branch
  );
  const { data: issueDataList, isPending: isIssuePending } = useIssue(
    searchFilter?.issue
  );
  const { data: userDataList, isPending: isUserPending } = useUser(
    searchFilter?.user
  );
  const { data: userAssigneeList, isPending: isAssigneePending } = useAssignee({
    id: selectedProject?.value,
    ...searchFilter?.assignee,
  });
  const { data: projectDataList, isPending: isProjectPending } = useProject(
    {
      id: user?.id,
      ...searchFilter?.project,
    },
    id,
    disableFields?.projectIds
  );

  const { data: uKeyData, isPending: isuKeyPending } = useUkey(
    selectedProject?.value,
    currentUKey,
    id
  );
  const { data: categoryList, isPending: isCategoryPending } = useCategory(
    searchFilter?.category,
    id
  );
  const { data: sprintList, isPending: isSprintPending } = useSprint(
    {
      id: project?.id,
      ...searchFilter?.sprint,
    },
    id
  );

  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);

  const issueTypesOptions = useMemo(() => {
    return issueDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [issueDataList]);

  const branchOptions = useMemo(() => {
    return branchDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [branchDataList]);

  const reporterOptions = useMemo(() => {
    return userDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [userDataList]);

  const assigneeOptions = useMemo(() => {
    return userAssigneeList?.result?.items?.map((itm: any) => itm) || [];
  }, [userAssigneeList, selectedProject?.value]);

  const uKey = useMemo(() => {
    return uKeyData?.result || "";
  }, [uKeyData, selectedProject?.value]);

  const taskByIdData = useMemo(() => {
    return getTaskInFormFormatData(taskData?.result) || undefined;
  }, [taskData]);

  const categoryOptions = useMemo(() => {
    if (categoryList?.message)
      message.error(`Error in Label list options => ${categoryList?.message}`);
    return categoryList?.result?.items?.map((itm: any) => itm) || [];
  }, [categoryList]);

  const sprintOptions = useMemo(() => {
    if (sprintList?.message)
      message.error(`Error in Sprint list options => ${sprintList?.message}`);
    return sprintList?.result?.items?.map((itm: any) => itm) || [];
  }, [sprintList]);

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(true);
  const [myDescription, setMyDescription] = useState<any>();

  const [activeKey, setActiveKey] = useState<string>("");
  const [isCreateBranch, setIsCreateBranch] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const navigate = useNavigate();
  const assigneeToMe = useCallback(
    (assignToMe: { label: string; value: string } | undefined) => {
      const assignees = form.getFieldValue("assigneeIds");
      const isAlready = assignees?.find((x: any) => x?.value === user?.id);
      if (!!assignToMe) {
        if (assignees?.length > 0) {
          if (!isAlready)
            form.setFieldValue("assigneeIds", [assignToMe, ...assignees]);
        } else form.setFieldValue("assigneeIds", [assignToMe]);
        setAssignToMeChecked(true);
      } else {
        const restAssignees = assignees?.filter(
          (x: any) => x?.value !== user?.id
        );
        if (assignees?.length > 0)
          form.setFieldValue(
            "assigneeIds",
            restAssignees?.length > 0 ? restAssignees : undefined
          );
        setAssignToMeChecked(false);
      }
    },
    []
  );

  const isAssigneeLoginUser = useCallback(
    (): boolean | undefined =>
      form
        ?.getFieldValue("assigneeIds")
        ?.find((x: any) => x?.value === user?.id),
    []
  );
  const [assignToMeChecked, setAssignToMeChecked] =
    useState<any>(isAssigneeLoginUser);

  const getIcon = (fileName: string) => {
    const extension = fileName?.split(".")?.[1]?.toLocaleLowerCase();
    let icon = <FileUnknownOutlined style={{ fontSize: 16, color: "red" }} />;
    if (extension) {
      if (extension === "xlsx") icon = <FileExcelOutlined />;
      else if (extension === "gif") icon = <FileGifOutlined />;
      else if (
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png" ||
        extension === ".apng" ||
        extension === ".avif" ||
        extension === "webp" ||
        extension === "ico" ||
        extension === "svg"
      )
        icon = <FileImageOutlined />;
      else if (extension === "pdf" || extension === "pdfx")
        icon = <FilePdfOutlined style={{ fontSize: 16, color: "red" }} />;
      else if (extension === "ppt" || extension === "pptx")
        icon = <FilePptOutlined />;
      else if (extension === "txt") icon = <FileTextOutlined />;
      else if (extension === "doc" || extension === "docx")
        icon = <FileWordOutlined />;
      else if (extension === "zip") icon = <FileZipOutlined />;
      else if (extension === "html")
        icon = <Html5Outlined style={{ fontSize: 16, color: "red" }} />;
      else if (extension === "js") icon = <JavaScriptOutlined />;
    }

    return icon;
  };

  const postTask = async (data: any) => {
    try {
      let previousTaskData: any = undefined;
      let updateData: any = undefined;

      if (!!id) {
        const taskDetail = sessionStorage.getItem("taskDetail") || "";
        if (!!!taskDetail) {
          setTimeout(() => {
            onDrawerClose();
          }, 800);
          const res: IApiResponse = {
            data: undefined,
            result: undefined,
            status: false,
            message: "Error in post Task logs",
            type: (type: any, arg1: string) => "",
          };
          return res;
        }
        previousTaskData = JSON.parse(taskDetail);
        updateData = {
          ...data?.parent,
          childTasks: [...data?.childTasks],
        };
      }

      const convertedData = await getTaskPostFormatData(
        data?.parent,
        user,
        data?.childTasks,
        taskMode
      );
      const response = await mutateAsync(convertedData);
      if (response?.result && !!!response?.message) {
        const project = response?.result?.parent?.project?.[0];
        if (project) {
          if (!!id) {
            const logs: any[] = [];
            getLogs(
              {
                tid: id,
                assigneeName: user?.name,
                assigneeId: user?.id,
                uKey: updateData?.uKey,
                pId: project?.id,
                cngType: 3,
                isComment:2,
                remarks: `${user?.name} Update The `,
              },
              previousTaskData,
              updateData,
              logs
            );
            if (logs?.length > 0) {
              const logPayload = {
                taskId: id,
                user: {
                  id: user?.id,
                  name: user?.name,
                },
                project:{
                  id:project?.id,
                  name:project?.name
                },
                logs: logs,
              };
              debugger;
              console.log("ttttttttttttt = ", logPayload);
              const rt = await taskLogMutateAsync(logPayload);
              debugger;
              console.log(rt);
            }
          }

          dispatch(setProjectData({ id: project?.id, name: project?.name }));
          return response;
        }
      }
    } catch (e) {
      message.error(`Error in postTask => ${e}`);
      console.error(`Error in postTask => ${e}`);
    }
  };
  console.log("tas45435345", taskByIdData);

  const onFinish = async (isSave: boolean) => {
    let apiRes: IApiResponse<any> | undefined = undefined;
    await form.validateFields();
    await childForm.validateFields();
    const parentTaskValues = await form.getFieldsValue();
    const childTaskValues = await childForm.getFieldValue("childTasks");
    const parentFiles = await form.validateFields(["file"]);
    const data = {
      parent: {
        ...parentTaskValues,
        uKey: form.getFieldValue("uKey"),
        id: form?.getFieldValue("id"),
        taskId: form?.getFieldValue("taskId") || parentId,
        file: parentFiles?.file?.map((itm: any) =>
          itm?.id ? itm : itm?.convertedFile
        ),
      },
      childTasks: childTaskValues?.map((task: any) => ({
        ...task,
        file: task?.file?.map((itm: any) =>
          itm?.id ? itm : itm?.convertedFile
        ),
      })),
    };
    apiRes = await postTask(data);
    if (apiRes?.message) {
      message.error(`Error =>  ${apiRes?.message}`);
    } else {
      if (!isSave) setCurrentUKey(uKey);
      message.success("Task created successfully");
      setTimeout(() => {
        onDrawerClose(isSave);
      }, 800);
    }
  };
  console.log("selectedProjectselectedProject = ", selectedProject);
  //#region TabItems

  const tabItems: any = [
    {
      key: "1",
      label: "Description",
      children: (
        <>
          <Form form={form}>
            <Form.Item name={"description"}>
              <SunEditor
                setContents={form.getFieldValue("description")}
                height="180"
                width="100%"
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["bold", "underline", "italic"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],
                    ["outdent", "indent"],
                    ["link", "image"],
                    ["removeFormat"],
                  ],
                  defaultTag: "div",
                  showPathLabel: false,
                }}
              />
            </Form.Item>
          </Form>
        </>
      ),
    },
    // Add childTask TAB
    {
      key: "2",
      label: (
        <>
          Child task
          {childForm?.getFieldValue("childTasks")?.length > 0 ? (
            <>
              <span style={{ marginLeft: 3 }}>
                ({childForm?.getFieldValue("childTasks")?.length})
              </span>
            </>
          ) : null}
        </>
      ),
      children: (
        <>
          <AddEditChildTask
            taskId={id}
            onChange={() => {
              setRefresh(!refresh);
            }}
            fieldName="file"
            Columns={[
              {
                key: "uKey",
                dataIndex: "uKey",
                title: "UKey",
                width: "10%",
                align: "center",
                render: (_: any, record: any) =>
                  record?.uKey ? (
                    <>
                      <Link
                        to={`/task/${record?.id}`}
                        className="BreadcrumbLink"
                      >
                        {record?.uKey}
                      </Link>
                    </>
                  ) : (
                    <>-</>
                  ),
              },
              {
                key: "title",
                dataIndex: "title",
                title: "Title",
              },
            ]}
            Form={{ form: childForm }}
            FormListName="childTasks"
          />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          Attachment
          {form?.getFieldValue("file")?.length > 0 ? (
            <>
              <span style={{ marginLeft: 3 }}>
                ({form?.getFieldValue("file")?.length})
              </span>
            </>
          ) : null}
        </>
      ),
      children: (
        <>
          <Form form={form}>
            <FilesUploader
              Form={{ form: form }}
              fieldName="file"
              onChange={() => {
                setRefresh(!refresh);
              }}
            />
          </Form>
        </>
      ),
    },
  ];

  //#endregion

  const onDrawerClose = (isSave: boolean = true) => {
    form.resetFields();
    childForm.resetFields();
    setAssignToMeChecked(false);
    if (isSave) {
      form.resetFields();
      setIsOpenDrawer(!isOpenDrawer);
      setTimeout(() => {
        onDismiss && onDismiss(false);
      }, 300);
    }
  };

  const preserveAssignToMe = useCallback(() => {
    if (isAssigneeLoginUser()) {
      form.setFieldValue(
        "assigneeIds",
        form
          .getFieldValue("assigneeIds")
          ?.filter((x: any) => x?.value === user?.id)
      );
    } else form.resetFields(["assigneeIds"]);
  }, []);

  const getDrawerHeader = useMemo(() => {
    if (id) {
      return (
        <>
          <>
            <Row align={"middle"}>
              <TagOutlined className="sidebarcss mr-3" />

              {/* <Button
                type="text"
                // className="fw500 fs20"
                size="small"
                onClick={() => navigate(`/task/${getByIdConvertedData?.uKey}`)}
              > */}
                {getByIdConvertedData?.uKey}
              {/* </Button> */}
              <Typography.Text
             className="mr-12 ml-7"
             style={{
               fontSize: "13px",
               color: "#8b99a7",
             }}
              >
                Created Date{" "}
                {moment(getByIdConvertedData?.createdBy?.date)?.format(
                  "MMM DD, YYYY [at] hh:mm A"
                )}
              </Typography.Text>

              <Typography.Text
                style={{
                  fontSize: "13px",
                  color: "#8b99a7",
                }}
              >
                Last updated{" "}
                {dayjs(getByIdConvertedData?.updatedBy?.date)?.format(
                  "MMM DD, YYYY [at] hh:mm A"
                )}
              </Typography.Text>
            </Row>
          </>
        </>
      );
    }
    return (
      <>
        <>
          Add a Issue
          <Typography.Text
            style={{ fontWeight: 600, fontSize: 15, marginLeft: "10px" }}
          >
            {uKey !== "" ? `(${uKey})` : ""}
          </Typography.Text>
        </>
      </>
    );
  }, [uKeyData, getByIdConvertedData]);

  const getDrawerFooter = useMemo(() => {
    if (id) {
      return (
        <>
          <Row gutter={10} justify={"end"}>
            <Col>
              <Button
                className="capTask-btn-light btn-m-sm"
                onClick={() => {
                  onDrawerClose();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                loading={(!!id ? isTaskLogs : false) || isTaskPost}
                className="btn-m-sm"
                type="primary"
                onClick={() => {
                  onFinish(true);
                }}
              >
                Update
              </Button>
            </Col>
          </Row>
        </>
      );
    }
    return (
      <>
        <Row gutter={10} justify={"end"}>
          <Col>
            <Button
              className="capTask-btn-light btn-m-sm"
              onClick={() => {
                onDrawerClose();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              loading={isTaskPost}
              className="btn-m-sm"
              type="primary"
              onClick={() => {
                onFinish(true);
              }}
            >
              Save
            </Button>
          </Col>
          <Col>
            <Button
              loading={isTaskPost}
              className="btn-m-sm"
              type="primary"
              onClick={() => {
                onFinish(false);
              }}
            >
              Save and New
            </Button>
          </Col>
        </Row>
      </>
    );
  }, [isTaskPost]);

  React.useEffect(() => {
    if (!!taskData?.result && id) {
      form.setFieldsValue({ ...taskByIdData });
      childForm.setFieldsValue({
        childTasks: Array.isArray(taskByIdData?.childTasks)
          ? [...taskByIdData?.childTasks]
          : undefined,
      });
      setAssignToMeChecked(isAssigneeLoginUser());
      setGetByIdConvertedData({ ...taskByIdData });
    }
  }, [taskByIdData]);

  return (
    <>
      {/* Modal Antd */}
      <Drawer
        title={getDrawerHeader}
        extra={
          !id ? (
            <CloseOutlined
              className="cursor"
              onClick={() => {
                onDrawerClose();
              }}
            />
          ) : null
        }
        closeIcon={id ? <ArrowLeftOutlined /> : false}
        width={850}
        maskClosable={false}
        onClose={() => {
          onDrawerClose();
        }}
        open={isOpenDrawer}
        footer={getDrawerFooter}
      >
        <>
          <Spin
            spinning={
              (id
                ? isGetTask
                : disableFields?.projectIds
                ? false
                : isProjectPending) || // Edit case no need of project list
              isAssigneePending ||
              isBranchPending ||
              isIssuePending ||
              (id ? false : isuKeyPending) || // Edit case no need of uKey
              (id ? isSprintPending : false) || // Edit case  need of sprint
              (id ? isCategoryPending : false) || // Edit case  need of label
              isUserPending
            }
            indicator={<LoadingOutlined spin={false} />}
            className="DrawerPadding"
          >
            <Tabs
              className="DrawerPadding"
              defaultActiveKey="1"
              items={[
                {
                  key: "taskDetails",
                  label: "Task Details",
                  children: (
                    <>
                      <Form
                        key={id || "addEditTaskForm"}
                        form={form}
                        labelAlign="left"
                        layout="horizontal"
                        colon={false}
                        labelCol={{ span: 5 }}
                        requiredMark={false}
                        initialValues={{
                          dueDate: dayjs(),
                          reportersIds: {
                            label: user?.name,
                            value: user?.id,
                          },
                          orderNo: 0,
                          projectIds: {
                            label: project?.name,
                            value: project?.id,
                          },
                          priority: 0,
                        }}
                        className=""
                      >
                        <>
                          <div>
                            <Form.Item name="projectIds" label="Project">
                              <Select
                                labelInValue
                                disabled={!!id || disableFields?.projectIds}
                                searchValue={searchFilter?.project?.search}
                                variant="borderless"
                                className="borderLessInput w100"
                                options={projectOptions}
                                onSelect={(opt: any) => {
                                  preserveAssignToMe();
                                  setSelectedProject({
                                    label: opt?.label,
                                    value: opt?.value,
                                  });
                                }}
                                placeholder="Choose Project"
                                showSearch
                                onSearch={(value) => {
                                  setSearchFilter((pre: any) => ({
                                    ...pre,
                                    project: {
                                      search: value,
                                    },
                                  }));
                                }}
                                optionFilterProp="label"
                              />
                            </Form.Item>

                            <Form.Item
                              name="title"
                              label="Title"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the Title!",
                                },
                                {
                                  whitespace: true,
                                  message: "Title cannot be empty!",
                                },
                                {
                                  min: 3,
                                  message:
                                    "Title must be at least 3 characters long!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Title"
                                variant="borderless"
                                className="borderLessInput w100"
                                autoFocus
                              />
                            </Form.Item>

                            <Form.Item
                              name="priority"
                              label="Priority"
                              rules={[
                                {
                                  required: true,
                                  message: "this field is required",
                                },
                              ]}
                            >
                              <Select
                                variant="borderless"
                                className="borderLessInput w100"
                                options={[
                                  { label: "High", value: 2 },
                                  { label: "Medium", value: 1 },
                                  { label: "Low", value: 0 },
                                ]}
                                placeholder="Choose Priority"
                                optionFilterProp="label"
                              />
                            </Form.Item>

                            <Form.Item label="Due Date" name="dueDate">
                              <DatePicker
                                className="borderLessInput w100"
                                format={"DD/MM/YYYY"}
                              />
                            </Form.Item>

                            {id ? (
                              <>
                                <Form.Item name="sprintIds" label="Sprint">
                                  <Select
                                    labelInValue
                                    variant="borderless"
                                    className="borderLessInput w100"
                                    placeholder="Choose issue Type"
                                    allowClear
                                    searchValue={searchFilter?.sprint?.search}
                                    options={sprintOptions}
                                    showSearch
                                    onSearch={(value) => {
                                      setSearchFilter((pre: any) => ({
                                        ...pre,
                                        sprint: {
                                          search: value,
                                        },
                                      }));
                                    }}
                                    optionFilterProp="label"
                                  />
                                </Form.Item>
                                <Form.Item name="categoryIds" label="Category">
                                  <Select
                                    labelInValue
                                    variant="borderless"
                                    className="borderLessInput w100"
                                    placeholder="Choose issue Type"
                                    allowClear
                                    searchValue={searchFilter?.category?.search}
                                    options={categoryOptions}
                                    showSearch
                                    onSearch={(value) => {
                                      setSearchFilter((pre: any) => ({
                                        ...pre,
                                        category: {
                                          search: value,
                                        },
                                      }));
                                    }}
                                    optionFilterProp="label"
                                  />
                                </Form.Item>

                                <Form.Item name="tStatus" label="Status">
                                  <Select
                                    variant="borderless"
                                    className="borderLessInput w100"
                                    options={TaskStatusOptions}
                                    placeholder="Choose Project"
                                    showSearch
                                    optionFilterProp="label"
                                  />
                                </Form.Item>
                              </>
                            ) : null}

                            <Form.Item
                              name="labelIds"
                              label="Issue Type"
                              rules={[
                                { required: true, message: "Select label!" },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                maxTagCount="responsive"
                                labelInValue
                                variant="borderless"
                                className="borderLessInput w100"
                                placeholder="Choose issue Type"
                                allowClear
                                searchValue={searchFilter?.issue?.search}
                                options={issueTypesOptions}
                                showSearch
                                loading={isIssuePending}
                                onSearch={(value) => {
                                  setSearchFilter((pre: any) => ({
                                    ...pre,
                                    issue: {
                                      search: value,
                                    },
                                  }));
                                }}
                                optionFilterProp="label"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Branch"
                              name="branch"
                              rules={[
                                {
                                  required: false,
                                  message: "Please select label!",
                                },
                              ]}
                            >
                              <Select
                                maxTagCount="responsive"
                                allowClear
                                variant="borderless"
                                className="borderLessInput w100"
                                searchValue={searchFilter?.branch?.search}
                                options={branchOptions}
                                placeholder="Choose Branch"
                                labelInValue
                                showSearch
                                mode="multiple"
                                optionFilterProp="label"
                                loading={isBranchPending}
                                onSearch={(value) => {
                                  setSearchFilter((pre: any) => ({
                                    ...pre,
                                    branch: {
                                      search: value,
                                    },
                                  }));
                                }}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <Space style={{ padding: "0 0px 4px" }}>
                                      <Button
                                        size="small"
                                        type="link"
                                        icon={<PlusOutlined />}
                                        onClick={() => setIsCreateBranch(true)}
                                      >
                                        Add Branch
                                      </Button>
                                    </Space>
                                  </>
                                )}
                              />
                            </Form.Item>

                            <Form.Item label="Reporter" name="reportersIds">
                              <Select
                                labelInValue
                                variant="borderless"
                                className="borderLessInput w100"
                                placeholder="Choose reporter"
                                allowClear
                                options={reporterOptions}
                                searchValue={searchFilter?.user?.search}
                                showSearch
                                onSearch={(value) => {
                                  setSearchFilter((pre: any) => ({
                                    ...pre,
                                    user: {
                                      search: value,
                                    },
                                  }));
                                }}
                                optionFilterProp="label"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Assignee"
                              name="assigneeIds"
                              rules={[
                                { required: true, message: "Select-assignee!" },
                              ]}
                            >
                              <Select
                                maxTagCount="responsive"
                                onChange={(
                                  selectedOptions: {
                                    label: string;
                                    value: string;
                                  }[]
                                ) => {
                                  assigneeToMe(
                                    selectedOptions?.find(
                                      (x: any) => x?.value === user?.id
                                    )
                                  );
                                }}
                                searchValue={searchFilter?.assignee?.search}
                                mode="multiple"
                                maxCount={5}
                                labelInValue
                                variant="borderless"
                                className="borderLessInput w100"
                                placeholder="Choose assignee"
                                allowClear
                                options={assigneeOptions}
                                showSearch
                                onSearch={(value) => {
                                  setSearchFilter((pre: any) => ({
                                    ...pre,
                                    assignee: {
                                      search: value,
                                    },
                                  }));
                                }}
                                optionFilterProp="label"
                              />
                            </Form.Item>

                            <Row justify="start" style={{ marginBottom: 15 }}>
                              <Col offset={5}>
                                <Checkbox
                                  checked={assignToMeChecked}
                                  onClick={(e: any) => {
                                    if (e?.target?.checked)
                                      assigneeToMe({
                                        label: user?.name,
                                        value: user?.id,
                                      });
                                    else assigneeToMe(undefined);
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#091E42",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Assign to me
                                  </span>
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>

                          {/* Description Antd */}
                        </>
                        {/* Save,Save and New button  */}
                      </Form>

                      <Tabs
                        defaultActiveKey="1"
                        className=""
                        // style={{ marginTop: 50 }}
                        items={
                          tabItems
                          // configData?.parantTaskId === ""
                          //   ? tabItems
                          //   : tabItems?.filter((x: any) => x?.key === "1")
                        }
                        tabBarExtraContent={
                          <>
                            {activeKey === "2" && !!id && (
                              <>
                                <Select
                                  style={{ width: 220 }}
                                  size="small"
                                  variant="borderless"
                                  defaultValue={taskMode}
                                  placeholder={"Child task creation method"}
                                  options={[
                                    {
                                      label: (
                                        <>
                                          <Space
                                            size={"small"}
                                            split={
                                              <Divider
                                                type="vertical"
                                                style={{ margin: 0 }}
                                              />
                                            }
                                          >
                                            <HighlightOutlined />
                                            Standard
                                          </Space>
                                        </>
                                      ),
                                      value: 1,
                                    },
                                    {
                                      label: (
                                        <>
                                          <Space
                                            size={"small"}
                                            split={
                                              <Divider
                                                type="vertical"
                                                style={{ margin: 0 }}
                                              />
                                            }
                                          >
                                            <FormOutlined />
                                            Drawer
                                          </Space>
                                        </>
                                      ),
                                      value: 2,
                                    },
                                  ]}
                                  onSelect={(value) => {
                                    dispatch(setTaskMode(value));
                                    setRefresh(!refresh);
                                  }}
                                />
                              </>
                            )}
                          </>
                        }
                        size="small"
                        onChange={(k: string) => {
                          setActiveKey(k);
                        }}
                      />
                    </>
                  ),
                },
                {
                  key: "taskActivity",
                  label: "Task Activity",
                  children: (
                    <TaskActivity
                      taskData={taskData?.result}
                      projectId={selectedProject?.value}
                    />
                  ),
                },
              ]?.filter((x: any) => (!!!id ? x?.key === "taskDetails" : true))}
            />
          </Spin>
        </>
        {isCreateBranch && (
          <AddEditBranch
            onDismiss={() => {
              setIsCreateBranch(false);
            }}
            data={undefined}
          />
        )}
      </Drawer>
    </>
  );
};
export default React.memo(AddEditTask);
