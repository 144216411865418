import React, { useState } from "react";
import IssueReportFilter from "../components/commoncomponents/IssueToothbar";
import HTTPSCalls from "../Services/HTTPCalls";
import IssuesTable from "./IssuesTable/IssuesTable";
import { Breadcrumb, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userData } from "../store/slices/user-slice";

export enum userType {
  All,
  Assignee,
  AssignedBy,
  Reporter,
}
interface IUserFilter {
  userType: userType;
  id: string;
}
interface IFormData {
  project: string[];
  SearchText: string;
  status: number[];
  priority: number;
  userFilter: IUserFilter;
}

const IssuesReport = () => {
  const [userbyproject, setuserbyproject] = useState<any>([]);
  const [startpage, setStartpage] = useState(0);
  const [IsLoading, setIsloading] = useState(false);
  const user = useSelector(userData);
  const [formData, setFormData] = useState<IFormData>({
    project: [],
    SearchText: "",
    status: [0, 1, 2, 3, 4, 5, 7],
    priority: -1,
    userFilter: { userType: userType.All, id: user?.id },
  });

  const getReportTasks = async (withSearch: boolean = false) =>
    await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.issues + "?", formData, {
      start: withSearch ? 0 : startpage,
      length: 30,
    });

  const ReporterCall = async (isManual: boolean = false) => {
    if (!isManual) {
      let res = await getReportTasks();
      if (res?.result && res?.result?.length > 0) {
        setuserbyproject([userbyproject, res?.result]);

        setStartpage(startpage + 30);
      } else {
        // setScrollloading(false);
      }
    } else {
      setIsloading(true);
      let res = await getReportTasks(true);
      if (res) {
        setStartpage(30);
        setuserbyproject(res?.result);
      }
      setIsloading(false);
    }
  };

  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/reports"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/reports"} className="BreadcrumbLink">
                    Reports
                  </Link>
                ),
              },
              {
                title: "Issues",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white">
        <Col span={24} className="ml-5">
          <IssueReportFilter
            ReporterCall={ReporterCall}
            setFormData={setFormData}
            setIsloading={setIsloading}
            IsLoading={IsLoading}
            userbyproject={userbyproject}
           
          />
        </Col>
        <Col span={24}>
          <IssuesTable
            userbyproject={userbyproject}
            getTask={getReportTasks}
            IsLoading={IsLoading}
          />
        </Col>
      </Row>
    
    </>
  );
};

export default IssuesReport;
