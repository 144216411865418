import { Droppable } from "@hello-pangea/dnd";
import { DraggableItemListView } from "./DraggableItemListView";
import { Button, Col, Row, Typography, Collapse } from "antd"; // Import Collapse
import { GRID_VIEW } from "../../constants/commonConstants";
import DraggableItemGridView from "./DraggableItemGridView";
import { formatDatedaytomonth } from "../../utils/DateConvertor";
import "../../common/common.css";
import { TagsOutlined } from "@ant-design/icons";
import { getEllipsisText } from "../../utils/Util";
import { useEffect, useState } from "react";

const { Panel } = Collapse; // Destructure Collapse to get Panel

const DroppableColumn = (props: any) => {
  const status = props?.status;
  const tasks = props?.tasks ? [...props?.tasks] : [];
  const [ellipsisLength, setEllipsisLength] = useState(13);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 1880) {
        setEllipsisLength(5);
      } else if (width > 1880 && width <= 1024) {
        setEllipsisLength(10);
      } else {
        setEllipsisLength(13);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        margin: "9px 5px",
        width: props?.viewMode === GRID_VIEW ? "70vh" : "auto",
        height: props?.viewMode === GRID_VIEW ? "72vh" : "auto",
        backgroundColor: "#F2F6FE",
        overflowY: props?.viewMode === GRID_VIEW ? "visible" : "auto",
        maxHeight: props?.viewMode === GRID_VIEW ? "100vh" : "50vh",
        borderRadius: 5,
      }}
    >
      {/* Render without Collapse for Grid View */}
      {props?.viewMode === GRID_VIEW ? (
        <div
        style={{
          borderRadius: 5,
          overflowY: "scroll",
          maxHeight: "96%",
          marginBottom: "12px",
        }}
        >
          <Row justify="space-between" align="middle"
          style={{
            position: "sticky",
            bottom: 0,
            top:0,
            zIndex:1,
            backgroundColor: "#F2F6FE",
            padding:'8px'}}>
            <Col className="p-2">
              <TagsOutlined style={{ color: "#B0BEC5" }} />
              <Typography.Text ellipsis
                className="resume-btn-sm"
                style={{
                  color: "#000",
                  border: "none",
                }}
              >
                {getEllipsisText(
                  props?.sprintData ? props?.sprintData?.name : status,
                  ellipsisLength
                )}
              </Typography.Text>
              {props?.sprintData && (
                <span className="pl-3 dataFormet">
                  {formatDatedaytomonth(props?.sprintData?.startDate)} -{" "}
                  {formatDatedaytomonth(props?.sprintData?.endDate)}
                </span>
              )}
            </Col>
            <Col style={{ textAlign: "center" }}>
              <Button
                size="small"
                className="resume-btn-sm mr-1"
                shape="round"
                style={{
                  backgroundColor: "rgb(204, 229, 255)",
                  width: "2vw",
                  border: "none",
                }}
              >
                {tasks?.length}
              </Button>
            </Col>
          </Row>
          <Droppable droppableId={status}>
            {(droppableProvided, snapshot) => (
              <div
                ref={droppableProvided?.innerRef}
                {...droppableProvided?.droppableProps}
                className={snapshot?.isDraggingOver ? "isDraggingOver" : ""}
                style={{
                  borderRadius: 5,
                  // overflowY: "scroll",
                  padding: "0px 6px",
                  // maxHeight: "92%",
                  // marginBottom: "12px",
                }}
              >
                {tasks?.map(
                  (task: any, index: number) =>
                    task?.UKey && (
                      <DraggableItemGridView
                        task={task}
                        index={index}
                        key={task?._id}
                        page={props?.page}
                        sourceDroppableId={status}
                        showLoading={props?.showLoading}
                        onSelectDropDown={props?.onSelectDropDown}
                        onClick={props?.onClick}
                      />
                    )
                )}
                {droppableProvided?.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ) : (
        /* Wrap ListView with Collapse */
        <Collapse
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          bordered={false}
        >
          <Panel
            header={
              <Row justify="space-between" align="middle">
                <Col>
                  <TagsOutlined style={{ color: "#B0BEC5" }} />
                  <Typography.Text
                    className="resume-btn-sm"
                    style={{
                      color: "#000",
                      border: "none",
                    }}
                  >
                    {props?.sprintData ? props?.sprintData?.name : status}
                  </Typography.Text>
                  {props?.sprintData && (
                    <span className="pl-3 dataFormet">
                      {formatDatedaytomonth(props?.sprintData?.startDate)} -{" "}
                      {formatDatedaytomonth(props?.sprintData?.endDate)}
                    </span>
                  )}
                </Col>

                <Col style={{ textAlign: "center" }}>
                  <Button
                    size="small"
                    className="resume-btn-sm mr-1"
                    shape="round"
                    style={{
                      backgroundColor: "rgb(204, 229, 255)",
                      width: "40px",
                      border: "none",
                    }}
                  >
                    {tasks?.length}
                  </Button>
                </Col>
              </Row>
            }
            key="1"
          >
            <Droppable droppableId={status}>
              {(droppableProvided, snapshot) => (
                <div
                  ref={droppableProvided?.innerRef}
                  {...droppableProvided?.droppableProps}
                  className={snapshot?.isDraggingOver ? "isDraggingOver" : ""}
                  style={{
                    borderRadius: 5,
                    overflowY: "scroll",
                    padding: "0px 6px",
                    maxHeight: "92%",
                    marginBottom: "12px",
                  }}
                >
                  {tasks?.map(
                    (task: any, index: number) =>
                      task?.UKey && (
                        <DraggableItemListView
                          task={task}
                          index={index}
                          key={task?._id}
                          page={props?.page}
                          sourceDroppableId={status}
                          showLoading={props?.showLoading}
                          onSelectDropDown={props?.onSelectDropDown}

                          onClick={props?.onClick}
                        />
                      )
                  )}
                  {droppableProvided?.placeholder}
                </div>
              )}
            </Droppable>
                     <div
            style={{
              position: "sticky",
              bottom: -1,
              padding: "8px",
              backgroundColor: "rgb(237, 241, 249 , 1)",
              zIndex: 1,
              // overflowY: "scroll",
              cursor: "pointer",
              borderRadius: 5,
            }}
          ></div>
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default DroppableColumn;
