
import {
  Button,
  Drawer,
  Modal,
  Table,
  TableProps,
  Tooltip,
  Typography,
} from "antd";
import {
  statusbackgroundgenerator,
  statusconvertor,
} from "../../../utils/Util";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../Common/TableSkeleton";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";

interface PendingTaskDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  isLoading: boolean;
  projectdatapending: any[];
}

export const PendingTaskModel: React.FC<PendingTaskDrawerProps> = ({
  isOpen,
  closeDrawer,
  isLoading,
  projectdatapending,
}) => {
  const navigate = useNavigate();

  const assigneeAvatar = (data: any) => {
    if (data.project.length > 0) {
      let arr = data.project[0].name.split(" ");
      if (arr.length > 1) {
        return (arr[0].charAt(0) + arr[1].charAt(0)).toUpperCase();
      } else {
        return (arr[0].charAt(0) + arr[0].charAt(1)).toUpperCase();
      }
    }
  };

  const prioritycheck = (priority: any) => {
    switch (priority) {
      case 2:
        return "High";
      case 1:
        return "Medium";
      case 0:
        return "Low";
      default:
        return "Unknown";
    }
  };

  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span style={{ color }}>
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span style={{ color }}>
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span style={{ color }}>
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };

  const columns = [
    {
      title:(
        <Typography.Text ellipsis>
           S. No.
        </Typography.Text>
      ),
      dataIndex: "serial",
      key: "serial",
      width: "10%",
      render: (text: string, record: any, index: number) => 
        <div className="pl-4">{text }</div>
      ,
    },
    {
      title: "Ukey",
      dataIndex: "key",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.key - b.key,
      render: (taskId: any, record: any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            color: "rgb(16, 124, 16)",
            backgroundColor: "rgb(223, 246, 221)",
            width: "5vw",
            border: "none",
          }}
          onClick={() => navigate("/task/" + record?.id)}
        >
          {record?.uKey}
        </Button>
      ),
      // width: "14%",
    },
    {
      title: "Parent",
      dataIndex: "Parent",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.Parent - b.Parent,
      render: (taskId: any, record: any) => (
        <p className="m-0">{record?.jira_UKey ? record?.jira_UKey : "-"}</p>
      ),
      // width: "14%",
    },
    {
      title: "Title",
      dataIndex: "title",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.title - b.title,
      render: (title: string, record: any) => (
        <Tooltip title={title}>
          <Typography.Text ellipsis
          
          >
            {substringmethod(title)}
          </Typography.Text>
        </Tooltip>
      ),
      // width: "20%",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.priority - b.priority,
      render: (priority: any, record: any) => (
        <>
          <Typography.Text  className="pr-2">
           <span className="PriorityRemove"> {priorityIcon(record?.priority)}</span>
          </Typography.Text>
          <Typography.Text ellipsis style={{ cursor: "pointer" }}>
            {prioritycheck(record?.priority)}
          </Typography.Text>
        </>
      ),
      width: "14%",
    },
    {
      title: "Status",
      dataIndex: "tStatus",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.Status - b.Status,
      render: (tStatus: any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: statusbackgroundgenerator(
              statusconvertor(tStatus)
            ),
            color: "rgb(12, 84, 96)",
            width: "110px",
            border: "none",
          }}
        >
          {statusconvertor(tStatus)}
        </Button>
      ),
      // width: "18%",
    },
  ];

  function substringmethod(data: string) {
    if (data?.length > 14) {
      return data.substring(0, 14) + "...";
    } else {
      return data;
    }
  }
 // pagination code
 const [listParams, setListParams] = useState({
  start: 0,
  length: 15,
});
const [refresh,setRefresh] = useState<boolean>(false)
const changeListParams = (key: string, value: any) => {
  setListParams((p: any) => {
    p[key] = value;
    return p;
  });
  
  //setListParams((x: any) => ({ ...x }));
};
const onChangeTable: TableProps<any>["onChange"] = (
  paging: any,
) => {
  
  if (paging.current !== undefined && paging.pageSize !== undefined) {
    changeListParams("start", (paging.current - 1) * paging.pageSize);
    changeListParams("length", paging.pageSize);
    setRefresh(!refresh)
  }
};
const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <Modal
    style={{padding: "12px 16px"}}
      width={880}
      className="drawer-pending-task"
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: 16, fontFamily:'Open Sans, sans-serif' }}>Pending Tasks</span>
        </div>
      }
      open={isOpen}
      onCancel={closeDrawer}
      footer={null}
    >
      {isLoading ? (
        <TableSkeleton
          columns={columns}
          scroll={{ y: "75vh" }}
          style={{
            scrollbarWidth: "none",
            borderTop: "solid 1px #b2bec3",
          }}
        />
      ) : (
        <Table
           className="capTask-custom-table"
        style={{ border:"1px solid #dfe6e9" ,borderRadius:3 ,padding: "12px 7px"}}
          columns={columns}
          dataSource={projectdatapending.map((data, index) => ({
            serial:index+1,
            key: index,
            ...data,
          }))}
          onChange={onChangeTable}
          pagination={{
            showTotal: (total: any, range: any) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          scroll={{ y: 500 }}
          locale={{
            emptyText: (
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                  paddingTop: "47px",
                }}
              >
                {/* <NoData message={undefined} /> */}
                <img
                  style={{ width: 140, paddingBottom: 15 }}
                  // src={process.env.PUBLIC_URL + "/noTask.svg"}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
                <div style={{ textAlign: "center", marginTop: "10%" }}>
                  
                  <h3
                    style={{ margin: 0, fontFamily: "Open Sans", fontSize: 20 }}
                  >
                    No task yet
                  </h3>
                  <p
                    style={{
                      marginTop: 4,
                      fontFamily: "Open Sans",
                      fontSize: 14,
                    }}
                  >
                    You have no available task to display
                  </p>
                </div>
              </Typography.Title>
            ),
          }}
        />
      )}
    </Modal>
  );
};
