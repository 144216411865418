import { ApiUtility } from "./ApiUtility";

class HTTPSCalls {
  static ENDPOINTS = {
    board:{
      taskListByBoard:"v1/api/Task/TaskListByBoard"
    },
    milestone: {
      getAll: "v1/api/Milestone/GetList",
      add: "v1/api/Milestone/Add",
      edit: "v1/api/Milestone/Edit",
      approve: "v1/api/Milestone/Approve",
      done: "v1/api/Milestone/Done",
      get: "v1/api/Milestone/Get",
      addLog: "v1/api/Milestone/AddLog",
      attachTask: "v1/api/Milestone/Attach",
      getLog: "v1/api/Milestone/GetLog",
      attachedTask: "v1/api/Milestone/AttachTask",
      addDuration: "v1/api/Milestone/AddDuration",
      getWithDuration: "v1/api/Milestone/GetListWithDurationTotal",
    },
    taskreport: {
      getAll: "v1/api/TaskReport/GetDoneTaskDuration",
      getWeeklyDoneTask: "v1/api/TaskReport/GetWeakWiseDoneTask",
    },
    login: {
      JWTauth: "/Account/Login",
    },
    sprintreport: {
      getAll: "v1/api/SprintReport/GetSprintWiseStatusRecords",
    },
    admin: {
      getAll: "v1/api/Admin/projectswithpercentagedonetask/projects",
      gettAllUsers: "v1/api/Admin/userswithpercentagedonetask/users",
      getAllDueMIlestone: "v1/api/Admin/expiredmilestones",
      getAllDueTasks: "v1/api/Admin/expiredtasks",
    },
    project: {
      get: "v1/api/Project",
      getAll: "v1/api/Project",
      addEdit: "/v1/api/Project",
      getProjectsListOptions: "/v1/api/Project/GetProjectsListOptions",
      getAssigneeListOptions: "/v1/api/Project/GetAssigneeListOptions",
      getUkey: "/v1/api/Project/GetUkey",
    },
    milestoneStages: {
      getAll: "v1/api/MilestoneStages/GetList",
    },
    task: {
      getbyukey: "v1/api/Task/ukey",
      getAll: "v1/api/Task",
      getAllByStatus: "v1/api/Task/ListStatusWiseArray",
      updateTask: "v1/api/update",
      attachment: "v1/api/Task/attachment",
      multipleTasks: "v1/api/Task/multipletask",
      userReportData: "v1/api/Task/UserReportData",
      addDuplicate: "v1/api/duplicate",
      issues: "v1/api/Task/issues",
      addFile: "v1/api/Task",
      getAllPending: "v1/api/Task/ProjectPendingTask",
      delay: "v1/api/Task/delay",
      filePreview: "v1/api/Task/filePreview",
      getAttachments: "v1/api/Task/files",
      getAttachmentDownload: "v1/api/Task/Download/",
      getCurrentUKey: "v1/api/currentukey",
      getAllBySprint: "v1/api/Task/ListSprintWiseArray",
      updateStatus: "v1/api/updatestatus",
      updateTaskSprint: "v1/api/Task/updateTaskSprint",
      watching: "v1/api/Task/watching",
      post :"/v1/api/Task",
      getById:"/v1/api/Task/GetById"
    },
    sprint: {
      getAll: "v1/api/Sprint?",
      sprintPost: "/v1/api/Sprint",
      getSprintListOptions:"v1/api/Sprint/GetSprintListOptions"
    },
    category:{
      getCategoryListOptions:"v1/api/Category/GetCategoryListOptions"
    },
    issueType: {
      getAll: "v1/api/Label",
      labelpost: "/v1/api/Label",
      getLabelListOptions: "/v1/api/Label/GetLabelListOptions",
    },
    BranchName: {
      getAll: "v1/api/Branch",
    },
    branch:{
      getBranchListOptions:"v1/api/Branch/GetBranchListOptions"
    },
    user: {
      getAll: "v1/api/user/assignee",
      create: "v1/api/user/create",
      resetPassword: "v1/api/user/create",
      getByProject: "v1/api/user/assigneebyproject",
      getAssigneeByTaskCount: "v1/api/user/assigneebytaskcount",
      getUserListOptions:"v1/api/user/GetUserListOptions",
      getUserList:"v1/api/user/GetUserList"
    },
    log: {
      get: "v1/api/Log",
      add: "v1/api/Log/list",
      getLogList:"v1/api/Log/GetLogList"
    },
    stage: {
      getAll: "v1/api/MilestoneStages/GetList",
      done: "v1/api/Milestone/StageDone",
    },
    userRoute: {
      getAll:"/v1/api/user/assignee?length=",
   
    },
    labelCatogeryRoute : {
      getAll:"v1/api/Category",
    },
    branchRoute : {
      getAll:"/v1/api/Branch?start=0&length=",
    },
    projectRoute : {
      getAll:"/v1/api/Project?start=0&length=",
    },
  };

  static GET = async (URL_BASE: string, URLParameaters: any = {}) => {
    return await ApiUtility.get(
      URL_BASE + new URLSearchParams({ ...URLParameaters }).toString()
    );
  };

  static POST = async (
    URL_BASE: string,
    requestBody: any,
    URLParameaters: any = {}
  ) => {
    return await ApiUtility.post(
      URL_BASE + new URLSearchParams({ ...URLParameaters }).toString(),
      requestBody
    );
  };
}

export default HTTPSCalls;
