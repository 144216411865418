import { useMutation, useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";

export const AddEditApis = {
  useTask: () =>
    useMutation({
      mutationFn: async (payload:any) => {
        const result = await addEditTask(payload);
        return result
      },
      onSuccess: (data: any) => {
        if (!data?.error) {
          queryClient.invalidateQueries({
            queryKey: ["taskGetById"],
          })
        }
      }
    }),
    useGetTask:(taskId:string|undefined|null=undefined)=>{
      return useQuery({ 
        enabled:!!taskId,
        queryKey: ["taskGetById",taskId],
        queryFn: () => getTaskById(taskId,taskId),
      })
    },
  useBranch: (payload: any={}) => {
    return useQuery({ 
      queryKey: ["taskBranchListOptions",payload],
      queryFn: () => getBranchList(payload),
    })
  },
  useIssue: (payload: any={}) => {
    return useQuery({
      queryKey: ["taskIssueListOptions",payload],
      queryFn: () => getIssueList(payload),
    })
  },
  useProject: (payload: any,taskId:string|undefined|null=undefined,callItSelfForChild:boolean|undefined=undefined) => {
    return useQuery({
      enabled:(!!!taskId&&!callItSelfForChild),
      queryKey: ["taskProjectList",payload],
      queryFn: () => getProjectsList(payload),
    })
  },
  useUser: (payload: any={}) => {
    return useQuery({
      queryKey: ["taskUserListOptions",payload],
      queryFn: () => getUserList(payload),
    })
  },
  useAssignee: (payload: any) => {
    return useQuery({
      queryKey: ["taskAssigneeListOptions",payload],
      queryFn: () => getAssigneeList(payload),
    })
  },
  useUkey: (projectId: string,currentUkey:string,taskId:string|undefined|null=undefined) => {
    return useQuery({
      enabled:!!!taskId,
      queryKey: ["taskUkey",{projectId,currentUkey}],
      queryFn: () => getCurrentUkey(projectId),
    })
  },
  useCategory: (payload: any={},taskId:string|undefined|null=undefined) => {
    return useQuery({
      enabled:!!taskId,
      queryKey: ["taskCategoryListOptions",payload],
      queryFn: () => getLabelList(payload),
    })
  },
  useSprint: (payload: any={},taskId:string|undefined|null=undefined) => {
    return useQuery({
      enabled:!!taskId,
      queryKey: ["taskSprintListOptions",payload],
      queryFn: () => getSprintList(payload),
    })
  },
}

const getLabelList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.category.getCategoryListOptions, {
    ...payload,
  });

const getSprintList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.sprint.getSprintListOptions, {
    ...payload,
  });
const getTaskById = async (id: string|undefined|null,ukey:string|undefined|null) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getById + "?", {
    id,
    ukey
  });

const addEditTask = async (payload: any) => {
  return HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.multipleTasks, payload)
};

const getCurrentUkey = async (projectId: string) =>
  await HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.project.getUkey+"?",{
      projectId
    }
  )

const getAssigneeList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.getAssigneeListOptions,{
    ...payload
  });

const getUserList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.user.getUserListOptions,{
    ...payload
  });

const getBranchList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.branch.getBranchListOptions,{
    ...payload
  });

const getIssueList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.issueType.getLabelListOptions,{
    ...payload
  });

const getProjectsList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.getProjectsListOptions, {
    ...payload
  });
