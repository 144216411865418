import { useMutation, useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";

export const useLogList = (payload: any) => {
  return useQuery({
    queryKey: ["getLogList",payload],
    queryFn: () => getLogList(payload),
  });
};

export const useAddComment = ()=>{
    return useMutation({
        mutationFn: async (payload:any) => {
          return await addComment(payload);
        },
        onSuccess: (data: any) => {
          if (!data?.error) {
            queryClient.invalidateQueries({
              queryKey: ["getLogList"],
            })
          }
        }
      })
}
export const useSaveTaskLog = ()=>{
    return useMutation({
        mutationFn: async (payload:any) => {
          return await addTaskLog(payload);
        },
        onSuccess: (data: any) => {
          if (!data?.error) {
            queryClient.invalidateQueries({
              queryKey: ["getLogList"],
            })
          }
        }
      })
}

const addTaskLog  = async(payload:any)=>
    await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.add,{
        ...payload
    })

const addComment  = async(payload:any)=>
    await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.add,{
        ...payload
    })

const getLogList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.getLogList,{
    ...payload
  });
