import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Row, Col, notification, Spin, Divider, Typography } from "antd";
import BooksService from "../Services/Books";
import "./Login.css";
import { UserOutlined } from "@ant-design/icons";
// import '../components/commoncomponents/common/common.css'
interface LoginHandleType {
  email: string;
  password: string;
  confirmPassword: string;
}

const Resetpassword: React.FC = () => {
  const validateSixDigit = (_: any, value: string) => {
    if (value && value.length < 6) {
      return Promise.reject("Please enter a 6-digit value!");
    }
    return Promise.resolve();
  };
  
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const param = new URLSearchParams(search);

  function useQuery() {
    const { search } = useLocation();
    console.log("search", search);

    return search;
  }
  const query = useQuery();
  const handleSubmit = async (values: LoginHandleType) => {
    setIsLoading(true);
    try {
      const { email, password, confirmPassword } = values;
      if (password !== confirmPassword) {
        notification.error({
          message: "New password doesn't match with confirm password",
          placement: "bottomLeft",
        });
      } else {
        const code = param.get("code");
        if (code) {
          const res = await BooksService.resetpassword({
            code: query.split("&code=")[1],
            confirmpassword: confirmPassword,
            email ,
            password,
          });
          if (res.result) {
            notification.success({
              message: "Password reset successfully",
              placement: "bottomLeft",
            });
            navigate("/");
          } else if (res.errors) {
            notification.error({
              message: res.message,
              placement: "bottomLeft",
            });
          } else {
            notification.error({
              message: "Error occurred",
              placement: "bottomLeft",
            });
          }
        }
      }
    } catch (err) {
      notification.error({ message: String(err), placement: "bottomLeft" });
    }
    setIsLoading(false);
  };
  return (
    <Spin spinning={isLoading}>
  <Row>
        {width >= 1024 ? (
          <Col
            span={13}
            style={{
              backgroundColor: "#F5F6F9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <div
              style={{ textAlign: "center", justifyContent: "center" }}
              className="pb-4"
            >
              <img
                src={process.env.PUBLIC_URL + "/tasklogin1.svg"}
                alt="tasklogin1"
              />
              <h2
                className="pb-2 pt-5"
                style={{
                  color: "#0E51D3",
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                }}
              >
                We're here to manage your work
              </h2>
              <h4
                style={{
                  color: "#253148",
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                We're here for your Work Management & Time Management with
                CapsiTask
              </h4>
            </div>
          </Col>
        ) : null}
        <Col
          span={width >= 1024 ? 10 : 24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
         <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{ width: "100%", maxWidth: 353 }}
          
          >
          
            <Form.Item style={{ paddingBottom: "20px" }}>
              <img
                style={{ width: "163px", cursor: "pointer" }}
                src={process.env.PUBLIC_URL + "/CapsiTask.png"}
                alt=""
              />
              <Divider style={{ color: "#dee2e6 " }} />
              <Typography.Text
                style={{
                  color: "rgb(34, 59, 149)",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
              Reset Password
              </Typography.Text>
            </Form.Item>
             <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input your email!" }]} hasFeedback
            >
              <Input
             
                  variant="borderless"
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#f9faff",
                    height: 30,
                  }}
                  suffix={<UserOutlined />}
              />
            </Form.Item> 
           
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter password!" },
                { validator: validateSixDigit },
              ]}
              // hasFeedback
            >
              <Input.Password
                // placeholder="Enter password"
                variant="borderless"
                style={{
                  borderRadius: 0,
                  backgroundColor: "#f9faff",
                  height: 30,
                }}
             
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                { required: true, message: "Please confirm your password!" },
                { validator: validateSixDigit },
              ]}
            >
               <Input.Password
                  variant="borderless"
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#f9faff",
                    height: 30,
                  }}
              
              />

            </Form.Item>
          
        
            <Link className="forgotButton" to="/login">
              {" "}
              <Typography.Link>Back to Login</Typography.Link>
            </Link>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                className="mt-5"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                
               
              >
                Submit
              </Button>
            </Form.Item>
            <Divider style={{ color: "#dee2e6 " }} />
          </Form>

    
       
        </Col>
      </Row>
    </Spin>
  );
};

export default Resetpassword;
