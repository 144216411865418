import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Col, Input, Row, Table, TableProps, } from "antd";
import { ArrowLeftOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import "../../common/SideMenu.css";
import AddEditBranch from "./AddEditBranch";
// import HTTPSCalls from '../../Services/HTTPCalls';
import { useGetAllBranchList } from "../../Services/RQMastersService";
import HTTPSCalls from "../../Services/HTTPCalls";
import { Link } from "react-router-dom";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
const { Search } = Input;
const SidebarBranch = () => {
  const {
    isLoading,
    data: branchData,
    isFetching,
  } = useGetAllBranchList("?start=0&length=100");
  const [showAddEditBranch, setShowAddEditBranch] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [, setIsLoading] = useState(false);
  const [branch, setBranch] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const onEdit = (record: any) => {
    setSelectedBranch(record);
    setShowAddEditBranch(true);
  };

  const columns = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "9%",
      render: (text: string, record: any, index: number) => 
        <div className="pl-4">{text}</div>
      ,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
          text
      ),
    },
        {
      title: "",
      dataIndex: "actions",
      key: "description",
      render: (text: any,record:any) => (
        <>
        <Row justify={"end"}>
       <FormOutlined className="capTask-edit-btn" onClick={() => onEdit(record)}/>
        {/* <DeleteOutlined className="capTask-delete-btn"/> */}
        </Row>
        </>
      ),
    }
  ];
  const filteredData = branchData?.result?.filter((item: { key:number , name: string }) =>
  item?.name?.toLowerCase()?.includes(searchFilter?.toLowerCase())
  );
  const handleSearchInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchFilter(event.target.value);
  };
  const GetBranchType = async () => {
    setIsLoading(true);
    try {
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.BranchName.getAll + "?",
        { start: 0, length: 100 }
      );
      // console.log("response", response);

      setBranch(response.result);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetBranchType();
  }, []);
  const [listParams, setListParams] = useState({
    start: 0,
    length: 10,
  });
  const [refresh,setRefresh] = useState<boolean>(false)
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    
    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging: any,
  ) => {
    
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh)
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <>
     <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
         <Link to={"/setting"}>
                  <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
                  </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/setting"} className="BreadcrumbLink">
                    Setting
                  </Link>
                ),
              },
              {
                title: "Branch",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
          <Col span={24}>
          <Row justify={"space-between"}>
            <Button
              icon={<PlusOutlined />}
              className="capTask-btn btn-clr-primary btn-primary-md mb-1"
              size="small"
              onClick={() => setShowAddEditBranch(true)}
            >
              Branch
            </Button>

            <Search
              size="small"
              placeholder="Search..."
              allowClear
              onChange={handleSearchInputChange}
              style={{ width: 175 }}
              className="mb-1"
            />
          </Row>
        </Col>
        <Col lg={24}>
        {isLoading ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
          <Table
            className="capTask-custom-table mt-3"
            size="small"
            scroll={{ y: 500 }}
            columns={columns}
            // dataSource={filteredData}
            dataSource={filteredData.map((data:any, index:number) => ({
              serial:index+1,
              key: index,
              ...data,
            }))}
            onChange={onChangeTable}
            pagination={{
              showTotal: (total: any, range: any) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: listParams.start / listParams.length + 1,
              pageSize: listParams.length,
              showSizeChanger: true,
              pageSizeOptions: pageSizeOption,
              position: ["bottomRight"],
            }}
            locale={{
              emptyText: (
                <img
                  style={{ paddingTop: "40px", width: 140 }}
                  // src={process.env.PUBLIC_URL + "/noTask.svg"}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
              ),
            }}
          />
          )}
        </Col>
      </Row>
      {showAddEditBranch && (
        <AddEditBranch
          onDismiss={() => {
            setShowAddEditBranch(false)
            setSelectedBranch(null)
          }
          }

          data={selectedBranch}
        />
      )}
    </>
  );
};

export default SidebarBranch;
