import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Col,
  Row,
  Table,
  Button,
  List,
  Typography,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAvatarUrl } from "../utils/Util";
import HTTPSCalls from "../Services/HTTPCalls";
import { ToothbarReport } from "./Peoplereportroute/ToothbarReport";
import { ColumnsType, TableProps } from "antd/es/table";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { AppRoutesPath } from "../Routes/AppRoutesPath";
import TableSkeleton from "./dashboard/Common/TableSkeleton";
import "../common/common.css";
export enum userStatus {
  All,
  Active,
  InActive,
}
const UserReport = ({ rowCount }: any) => {
  const columns: ColumnsType = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
       width: "9%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: string, record: any) => (
        <>
          <List itemLayout="horizontal" size="small" >
            <List.Item
              key={record?.id}
              style={{ padding: 0, alignItems: "center" }}
            >
              <List.Item.Meta
                style={{ padding: 0, alignItems: "center", }}
                key={`key-${record?.id}`}
                avatar={
                  <Avatar
                    icon={<UserOutlined />}
                    size={"small"}
                    src={record?.avatarUrl}
                    alt="user"
                  />
                }
                title={
                  <Link
                    to={`/${AppRoutesPath?.reports?.home}/${AppRoutesPath?.reports?.userReport}`}
                    state={{ userid: record?.id }}
                  >
                    <Typography.Text 
                      title={text}
                      ellipsis
                      // style={{
                      //   display: "flex",
                      //   justifyContent: "space-between",
                      //   alignItems: "center",
                      // }}
                    >
                      {text}
                    </Typography.Text>
                  </Link>
                }
              />
            </List.Item>
          </List>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      showSorterTooltip: false,
      // width: "20%",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      render: (text: string, record: any) => (
        // <Link
        //   style={{ cursor: "pointer", color: "#1677ff" }}
        //   to={`/${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`}
        //   state={{ userid: record?.id }}
        // >
        <>
          <Typography.Paragraph
            title={record?.email}
            ellipsis
            copyable
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {record?.email}
          </Typography.Paragraph>
        </>
        // </Link>
      ),
    },
    {
      title: "To Do",
      dataIndex: "todo",
      key: "todo",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.todo - b.todo,
      render: (text: number) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(209, 236, 241)",
            color: "rgb(12, 84, 96)",
            width: "5vw",
            border: "none",
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "In Progress",
      dataIndex: "inprogress",
      key: "inprogress",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.inprogress - b.inprogress,
      render: (text: number) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(204, 229, 255)",
            // color: "#64b4fe",
            width: "5vw",
            border: "none",
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Done",
      dataIndex: "done",
      key: "done",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Done - b.Done,
      render: (text: number) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(204, 209, 255)",
            // color: "rgb(204, 209, 255)",
            width: "5vw",
            border: "none",
          }}
        >
          {text}
        </Button>
      ),
    },
  ];
  const [usertaskcount, setusertaskcount] = useState([]);
  const [projectuserarrfilter, setprojectuserarrfilter] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [defaultCount, setdefaultCount] = useState<userStatus>(
    userStatus.Active
  );
  const [ReloadOutlined, setReloadOutlined] = useState(1);
  const navigate = useNavigate();
  const data = usertaskcount?.map((item: any, index: number) => ({
    serial: index + 1,
    key: index,
    id: item?.id,
    name: item?.name,
    imagePath: "",
    imageThumb: "",
    email: item?.email,
    todo: item?.todo,
    inprogress: item?.inprogress,
    done: item?.done,
    avatarUrl: getAvatarUrl(item),
  }));
  const fetchUserTaskCount = async () => {
    try {
      setIsLoading(true);

      const response = await HTTPSCalls.GET(
        `${HTTPSCalls.ENDPOINTS.user.getAssigneeByTaskCount}?isActive=${defaultCount}`,
        ReloadOutlined
      );
      setprojectuserarrfilter(response?.result);
      setusertaskcount(response?.result);
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchUserTaskCount();
  }, [defaultCount, ReloadOutlined]);

  const breadcrumbItems = [
    {
      title: (
        <Link to={"/dashboard"} className="BreadcrumbLink">
          Home
        </Link>
      ),
    },
    {
      title: "Peoples",
    },
  ];

  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    //setListParams((x: any) => ({ ...x }));
  };
  const [refresh, setRefresh] = useState<boolean>(false);
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <>
      <Row justify={"space-between"}>
        <Col span={2}>
          <Button type="link" onClick={() => navigate("/")}>
            <ArrowLeftOutlined />
          </Button>
        </Col>
        <Col>
          <Breadcrumb items={breadcrumbItems} />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24} className="mb-2">
          <ToothbarReport
            defaultCount={defaultCount}
            setdefaultCount={setdefaultCount}
            setprojectuserarr={setusertaskcount}
            projectuserarrfilter={projectuserarrfilter}
            setReloadOutlined={setReloadOutlined}
          />
        </Col>

        {/* <Loader loading={isLoading} /> */}

        <Col span={24}>
          {IsLoading ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
              className="capTask-custom-table"
              dataSource={data}
              columns={columns}
              onChange={onChangeTable}
              pagination={{
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
              // pagination={{
              //   onChange: cancel,
              // }}
              scroll={{ y: "70vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default UserReport;
