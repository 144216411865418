import React, { useMemo, useState } from "react";
import { Breadcrumb, Button, Col, ConfigProvider, Input, Row, Select, Table, TableProps, Typography } from "antd";
// import { TableLoadingSpinner } from '../AntDashboard/Common/TableLoadingSpinner';
// import { AssigneeAvatar } from '../AntDashboard/Common/AssigneeAvatar';
import AddEditSprint from "./AddEditSprint";
import { useGetSprintList } from "../../Services/RQMastersService";
import { useGetProjects } from "../../Services/RQDashboardService";
// import moment from 'moment';
import { ArrowLeftOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import { dayjs } from "../../utils/dayjs";
const SprintList = () => {
  const [payload, setPayload] = useState({
    projectId: "",
    length: 150,
  });
  const {
    isLoading: isLoading,
    data: sprintDataList,
    isFetching: isFetching,
  } = useGetSprintList(payload);
  const {
    isLoading: isProjectListLoading,
    data: projectData,
    isFetching: isFetching1,
  } = useGetProjects();
  const [show, setShow] = useState("");
  const [sprintData, setSprintData] = useState<any>(
    {
      projectId: "",
      name: "",
      data: {} || undefined,
    } || undefined
  );
  const projectsListOption = useMemo(() => {
    return projectData?.result?.map((project: any, index: any) => ({
      key: index,
      label: project?.name,
      value: project?.id,
    }));
  }, [projectData]);
  const getSprintList = useMemo(() => {
    return sprintDataList?.result?.map((sprint: any, index: any) => ({
      key: index,
      ...sprint,
    }));
  }, [sprintDataList]);
  const onEdit = (values: any) => {
    // console.log("...values",values);
    let val = {
      ...values,
      fromTo: [dayjs(values?.startDate), dayjs(values?.endDate)],
      // startDate: dayjs(values?.startDate),
      // endDate: dayjs(values?.endDate),
    };
    setSprintData({ ...sprintData, data: { ...val } });
    setShow("AddEditSprint");
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const columns = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "9%",
      render: (text: string, record: any, index: number) => 
        <div className="pl-4">{text}</div>
      ,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
          text
      ),
    },
        {
      title: "",
      dataIndex: "actions",
      key: "description",
      render: (text: any,record:any) => (
        <>
        <Row justify={"end"}>
       <FormOutlined className="capTask-edit-btn" onClick={() => onEdit(record)}/>
        {/* <DeleteOutlined className="capTask-delete-btn"/> */}
        </Row>
        </>
      ),
    }
  ];
  const [listParams, setListParams] = useState({
    start: 0,
    length: 10,
  });
  const [refresh,setRefresh] = useState<boolean>(false)
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    
    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging: any,
  ) => {
    
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh)
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/setting"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/setting"} className="BreadcrumbLink">
                    Setting
                  </Link>
                ),
              },
              {
                title: "Sprint",
              },
            ]}
          />
        </Col>
      </Row>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorTextPlaceholder: "#95a5a6",
              fontSize: 14,
              colorPrimaryHover: "none",
           
              fontSizeIcon: 10,
            
              paddingSM: 0,
              paddingXS: 0,
              paddingXXS: 0,
              lineWidth: 1,
              borderRadius: 0,
              borderRadiusLG: 0,
              borderRadiusSM: 0,
              borderRadiusXS: 0,
              controlPaddingHorizontal: 12,
              controlPaddingHorizontalSM: 12,
              controlHeight: 27,
              controlHeightLG: 27,

         
              algorithm: true,
              fontWeightStrong: 600,
            },
        
          },
        }}
      >
       
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"space-between"}>
            <Button
              icon={<PlusOutlined />}
              className="capTask-btn btn-clr-primary btn-primary-md mb-1"
              size="small"
             onClick={() => {
              setShow("AddEditSprint");
            }}
            >
              Sprints
            </Button>
              <Select
                style={{
                  backgroundColor: "#f3f4fa",
                  width: "250px",
                }}
                   className="capTask-custom-select"
                filterOption={filterOption}
                showSearch
                placeholder="Select Project"
                labelInValue
                // variant="borderless"
                loading={isProjectListLoading}
                options={projectsListOption}
                onSelect={(val: any) => {
                  setSprintData(() => {
                    return {
                      ...sprintData,
                      name: val?.label,
                      projectId: val?.value,
                    };
                  });
                  setPayload({ ...payload, projectId: val?.value });
                }}
              />
            {/* <Search
              size="small"
              placeholder="Search..."
              allowClear
              onChange={handleSearchInputChange}
              style={{ width: 175 }}
              className="mb-1"
            /> */}
          </Row>
        </Col>
        <Col lg={24}>
        {isLoading ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
          <Table
           className="capTask-custom-table mt-3"
            size="small"
            onChange={onChangeTable}
              pagination={{
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams?.start / listParams?.length + 1,
                pageSize: listParams?.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
            scroll={{ y: 500 }}
            columns={columns}
            // dataSource={getSprintList}
            dataSource={getSprintList?.map((data:any, index:number) => ({
              serial:index+1,
              key: index,
              ...data,
            }))}
            locale={{
              emptyText: (
                <img
                  style={{ paddingTop: "40px", width: 140 }}
                  // src={process.env.PUBLIC_URL + "/noTask.svg"}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
              ),
            }}
          />
          )}
        </Col>
      </Row>
      </ConfigProvider>
      {show === "AddEditSprint" && (
        <AddEditSprint
          onDismiss={() => {
            setShow("");
            setSprintData({ ...sprintData, data: undefined });
          }}
          data={sprintData}
        />
      )}
    </>
  );
};

export default SprintList;
