import { DeleteRowOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  FormItemProps,
  FormProps,
  message,
  Row,
  Space,
  Upload,
  UploadProps,
} from "antd";
import { NamePath } from "antd/es/form/interface";
import React, { ReactNode, useEffect } from "react";
import "./common.css"

interface IFileUploader {
  Form: FormProps;
  Upload?: UploadProps;
  fieldName: NamePath<any>;
  children?: ReactNode;
  FormItem?: FormItemProps;
  extra?: ReactNode;
  allowClear?: boolean;
  afterUpload?:()=>void;
  onChange?:()=>void;
}

const FileUploader = ({
  Form: FormProps,
  Upload: UploadProps,
  fieldName,
  children,
  FormItem: FormItemProps,
  extra,
  allowClear = false,
  afterUpload,
  onChange = ()=>{}
}: IFileUploader) => {
  const [frm]=  Form.useForm(FormProps.form)
  const normFile = (e: any) => {
    if (e?.fileList?.length <= 0) {
      FormProps?.form?.setFieldValue(fieldName, []);
      onChange()
      return [];
    }
    Promise.all(
      e?.fileList?.map(async (itm: any) => await getBase64(itm))
    ).then((values: any) => {
      const convertedData = values?.map((x: any) => ({
        ...x,
        ...x?.file,
        convertedFile: x?.id
          ? {
              ...x,
              existingType: 0,
            }
          : {
              name: x?.file?.name,
              existingType: 1,
              path: x?.base64,
              contentType: x?.file?.type,
              length: x?.file?.size,
            },
      }));
      console.log(
        "newFilesnewFiles = ",
        e?.fileList,
        " =>>>>>>>>>>>> ",
        convertedData
      );
      FormProps?.form?.setFieldValue(fieldName, [...convertedData]);
      afterUpload&& afterUpload()
      onChange()
    });

    return e?.fileList;
  };
  const getBase64 = async (file: any) => {
    if (file?.originFileObj) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader?.readAsDataURL(file?.originFileObj);
        reader.onload = () => {
          resolve({ file, base64: reader.result });
        };
        reader.onerror = reject;
      });
    }
    return file;
  };
  console.log("fileData12213123 = ","=>",fieldName ,"=>", frm?.getFieldsValue());
  console.log("1234324234324324 = ","=>",fieldName ,"=>", frm?.getFieldValue(fieldName));

  return (
    <>
      {/* <Form {...FormItemProps} form={frm}> */}
        <Space.Compact className="w100">
          <Form.Item
            {...FormItemProps}
            name={fieldName}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className={`${FormItemProps?.className} ${allowClear?"":"w100"}`}
          >
            <Upload
              {...UploadProps}
              listType={UploadProps?.listType ?? "picture"}
              multiple={UploadProps?.multiple ?? true}
              maxCount={UploadProps?.maxCount ?? 20}
              onPreview={async (file: any) => {
                // console.log("FileFileFile = ", file);
                let src = file?.path ?? file?.convertedFile?.path;
                let newWindow = window.open();
                if (newWindow) {
                  newWindow?.document.write(
                    `<iframe src="${src}" alt=${file?.name} style="width:100%;height:100%;border:none;" />`
                  );
                  newWindow.document.body.style.margin = "0px";
                  newWindow.document.title = file?.name;
                } else {
                  message.error(`Error during preview of ${file?.name}`);
                }
              }}
              beforeUpload={() => false}
            >
              {children ?? (
                <Button type="primary" icon={<UploadOutlined />} className="btn-clr-primary">
                  Upload
                </Button>
              )}
            </Upload>
          </Form.Item>
          <>
            {allowClear
              ? (
                extra ?? <Button icon={<DeleteRowOutlined />}>Clear</Button>
              )
              : null}
          </>
        </Space.Compact>
      {/* </Form> */}
    </>
  );
};
export default React.memo(FileUploader);
