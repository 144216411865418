import React, { useEffect, useMemo, useState } from "react";
import BooksService from "../../Services/Books";
import { Button, Col, ConfigProvider, Form, notification, Row, Select, TreeSelect, Typography } from "antd";
import { statusconvertor } from "../../utils/Util";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import "../../components/Reports/Reports.css";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { AddEditApis } from "../../Services/RQTaskService";
// import { useProject } from "../../Services/RQMastersService";
// import { projectData } from "../../store/slices/project-slice";
const Userratingtoothbar = ({
  fetchInHandData,
  statusFilter,
  page,
  setstatusFilter,
  projectid,
}: any) => {
  const [filterData, setFilterData] = useState<any>({
    projectid: "",
    assigneeId: "",
    status: 3,
  });
  const [form] = Form.useForm(); 
  // const project = useSelector(projectData);
  // const [selectedProject, setSelectedProject] = useState<any>(project?.id);
  const [assigneeavatardata, setassigneeavatardata] = useState([]);
  // const [projectDrowpdown, setProjectDropdown] = useState<any>([]);
  // const [width, setWidth] = useState<any>(window.innerWidth);
  const [assigneeselect, setAssigneeselect] = useState("");
  const user = useSelector(userData);
  const { useUser, useProject } = AddEditApis;
  const [searchFilter, setSearchFilter] = useState<{
    user: {
      search: undefined;
    };
    project: {
      search: undefined,
    },
  }>({
    user: {
      search: undefined,
    },
    project: {
      search: undefined,
    },
  });
  const { data: userDataList, isPending: isUserPending } = useUser(
    searchFilter?.user
  );
  // const reporterOptions = useMemo(() => {
  //   return userDataList?.result?.items?.map((itm: any) => itm) || [];
  // }, [userDataList]);
  const reporterOptions = useMemo(() => {
    return userDataList?.result?.items?.map((itm: any) => ({
      label: itm?.name, 
      value: itm?.id,   
      ...itm,   
   
    })) || [];
    
  }, [userDataList]);

  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  //GetUser Api
  const fetchassignee = () => {
    BooksService.getUser(100).then((res: any) => {
      let RANDOM_USERS: any = [];
      if (res.result?.length > 0) {
        for (let el in res.result) {
          RANDOM_USERS.push({
            id: res?.result[el]?.id,
            name: res?.result[el]?.name,
            thumb: res?.result[el]?.imageThumb,
          });
        }
        let assigneeavatar: any = [{ label: "All", value: "" }];
        res?.result.map((item: any) => {
          assigneeavatar.push({
            label: item?.name,
            value: item?.id,
            ...item,
          });
        });
        setassigneeavatardata(assigneeavatar);
      }
    });
  };

  useEffect(() => {
     fetchassignee();
    // fetchProjects();
  }, [projectid]);
const handleSearchClick = () => {
  form
    .validateFields()
    .then((values) => {
      if (!filterData?.projectid) {
      } else {
        // var assigneeselect:any = assigneeselect[0] as any;
        fetchInHandData(filterData?.projectid,assigneeselect, statusFilter);
      }
    })
    .catch((errorInfo) => {
      notification.error({
        message: "Select Project",
        description: "Please fill in the required fields correctly.",
        placement: "bottomLeft",
      });
    });
};

  // window.addEventListener("resize", () => setWidth(window.innerWidth));
  const { data: projectDataList, isPending: isProjectPending } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });
  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);

  return (
    <ConfigProvider
    theme={{
      components: {
        Select: {
          colorTextPlaceholder: "#d9d9d9",
          fontSize: 14,
          colorPrimaryHover: "none",
          fontSizeIcon: 12,
          paddingSM: 8,
          paddingXS: 8,
          paddingXXS: 4,
          lineWidth: 1,
          borderRadius: 4,
          borderRadiusLG: 4,
          borderRadiusSM: 4,
          borderRadiusXS: 4,
          controlPaddingHorizontal: 4,
          controlPaddingHorizontalSM: 4,
          controlHeight: 26,
          controlHeightLG: 26,
          algorithm: true,
          fontWeightStrong: 600,
        },
        Input: {
          controlHeight: 27,
          fontSizeIcon: 12,
          controlHeightLG: 27,
          inputFontSize: 14,
          colorTextPlaceholder: "#d9d9d9",
        },
      },
    }}
    >
      
              <Row justify={"space-between"} gutter={[16, 16]}>
                {/* Form Component */}
                <Col span={15}>
                  <Form layout="inline" form={form}>
                    <Form.Item
                      name="project"
                      rules={[{ required: true, message: "" }]}
                    >
                     
                        <Select
                        className="issueReport-custom-select"
                            style={{  width: "10vw" }}
                            loading={isProjectPending}
                            searchValue={searchFilter?.project?.search}
                            options={projectOptions}
                            onSelect={(value: any) => {
                              setFilterData({
                                ...filterData,
                                projectid: value,
                              });
                            }}
                            placeholder="Choose Project"
                            showSearch
                            onSearch={(value) => {
                              setSearchFilter((pre: any) => ({
                                ...pre,
                                project: {
                                  search: value,
                                },
                              }));
                            }}
                            optionFilterProp="label"
                          />
                    </Form.Item>
                    <Form.Item name="Status">
                      <Typography.Text className="mr-4 HideHand">Status</Typography.Text>
                      <TreeSelect
                 
                        treeData={[
                          { title: statusconvertor(1), value: 1 },
                          { title: statusconvertor(2), value: 2 },
                          { title: statusconvertor(3), value: 3 },
                          { title: statusconvertor(7), value: 7 },
                          { title: statusconvertor(4), value: 4 },
                          { title: statusconvertor(5), value: 5 },
                          { title: statusconvertor(6), value: 6 },
                        ]}
                        defaultValue={[
                          { title: statusconvertor(1), value: 1 },
                          { title: statusconvertor(2), value: 2 },
                          { title: statusconvertor(3), value: 3 },
                          { title: statusconvertor(7), value: 7 },
                          { title: statusconvertor(4), value: 4 },
                          { title: statusconvertor(5), value: 5 },
                        ]}
                        value={statusFilter}
                        treeCheckable
                        showCheckedStrategy="SHOW_PARENT"
                        placeholder="Status Filter"
                        maxTagCount={1}
                        onChange={setstatusFilter}
                        className="issueReport-custom-select"
                        style={{ width: "180px" }}
                        showSearch
                        onSearch={onSearch}
                        treeNodeFilterProp="title"
                      />
                    </Form.Item>
                    <Form.Item name="Assignee">
                      <Typography.Text  className="mr-4 mt-1 HideHand">
                        Assignee
                      </Typography.Text>
                   
                     <Select
                        // className="handsearchh"
                        className="issueReport-custom-select mt-1"
                        style={{ width: "10vw" }}
                        placeholder="Please Select"
                        options={assigneeavatardata}
                        onChange={(value) => {
                          setAssigneeselect(value);
                        }}
                        showSearch
                        allowClear
                        onSearch={onSearch}
                        optionFilterProp="label"
                        // suffixIcon={
                        //   <SearchOutlined
                        //     className="mb-1 mr-4"
                        //     onClick={handleSearchClick}
                        //     style={{
                        //       color: "rgba(0, 0, 0, 0.45)",
                        //       fontSize: "16px",
                        //       cursor: "pointer",
                        //     }}
                        //   />
                        // }
                      />
                  </Form.Item>
                  </Form>
                </Col>
                <Col className="mr-10">
                <Button
                  className="pl-5 pr-5 mr-4"
                  
                  type="primary"
                  style={{ borderRadius: "3px",height:'28px' , }}
                  size={"small"}
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
                <Button
              onClick={handleSearchClick}
              style={{ height: 27, }}
              icon={
                <ReloadOutlined
                  style={{ color: "rgb(61, 153, 37)", fontSize: "14px" }}
                />
              }
            />
                </Col>
              </Row>
      
    </ConfigProvider>
  );
};

export default Userratingtoothbar;
