import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
  notification,
} from "antd";
import React, { useMemo, useState } from "react";
import { statusconvertor } from "../../utils/Util";
import { userType } from "../../constants/commonConstants";
import { useGetProjects } from "../../Services/RQDashboardService";
import "../../common/common.css";
import {
  CloseOutlined,
  FilterOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { AddEditApis } from "../../Services/RQTaskService";
const IssueReportFilter = ({
  ReporterCall,
  setFormData,
  setIsLoading,
}: any) => {
  const [form] = Form.useForm();
  const { data: projectData } = useGetProjects();
  const { useUser } = AddEditApis;
  const [searchFilter, setSearchFilter] = useState<{
    user: {
      search: undefined;
    };
  }>({
    user: {
      search: undefined,
    },
  });
  const { data: userDataList, isPending: isUserPending } = useUser(
    searchFilter?.user
  );
  const reporterOptions = useMemo(() => {
    return (
      userDataList?.result?.items?.map((itm: any) => ({
        label: itm?.name,
        value: itm?.id,
        ...itm,
      })) || []
    );
  }, [userDataList]);
  const data = projectData?.result?.map((item: any) => ({
    label: item?.name,
    value: item?.id,
  }));

  const onSearch = (value: string) => {
    // Handle search functionality
  };
 
  const handleSearchClick = () => {
    form
      .validateFields()
      .then((values) => {
        // Check if the required field (like project) is null
        if (!values.project || values.project.length === 0) {
          form.resetFields();
          notification.error({
            message: 'Select Project',
            description: 'Please select a project before proceeding.',
            placement: 'bottomLeft',
          });
        } else {
          ReporterCall(true); // Call reporter if validation succeeds
        }
      })
      .catch(() => {
        notification.error({
          message: 'Select Project',
          description: 'Please fill in the required fields correctly.',
          placement: 'bottomLeft',
        });
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      handleSearchClick();
    }
  };



  const [showInput, setShowInput] = useState(false);
  const handleButtonClick = () => {
    setShowInput(true); // Show input when Filter button is clicked
  };

  const handleCloseClick = () => {
    setShowInput(false); // Hide input when Close button is clicked
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#d9d9d9",
            fontSize: 14,
            colorPrimaryHover: "none",
            fontSizeIcon: 12,
            paddingSM: 8,
            paddingXS: 8,
            paddingXXS: 4,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 28,
            controlHeightLG: 27,
            algorithm: true,
            fontWeightStrong: 600,
          },
          Input: {
            controlHeight: 27,
            fontSizeIcon: 12,
            controlHeightLG: 27,
            inputFontSize: 14,
            colorTextPlaceholder: "#d9d9d9",
          },
        },
      }}
    >
      <Form layout="horizontal" form={form}  onKeyDown={handleKeyDown} >
        <Row gutter={[16, 16]} align="middle" justify={"space-between"}>
          <Col style={{cursor:"pointer"}} span={5}>
            <Form.Item
              name="project"
              rules={[{ required: true, message: "" }]}
              className="m-0"
              style={{cursor:"pointer"}}
            >
              <Select
                placeholder="Select Project..."
                style={{ width: "270px"}}
                className="issueReport-custom-select mt-2"
                maxTagCount={"responsive"}
                options={data}
         
                mode="multiple"
                onChange={(value: any) => {
                  setFormData((prev: any) => ({
                    ...prev,
                    project: value?.map((item: any) => item),
                  }));
                }}
                optionFilterProp="label"
                onSearch={onSearch}
              />
            </Form.Item>
          </Col>

          <Col className="mr-9">
          <Button
          
                  className="pl-5 pr-5 mr-2"
                  type="primary"
                  style={{ borderRadius: "3px",height:'29px' }}
                  size={"small"}
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
            {/* <Form.Item name="search"> */}
            <Input
              allowClear
              onChange={(e: any) => {
                setFormData((prev: any) => ({
                  ...prev,
                  SearchText: e?.target?.value,
                }));
              }}
              suffix={
                <SearchOutlined
                  onClick={handleSearchClick}
                  style={{
                    color: "rgba(0, 0, 0, 0.45)",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
              }
              placeholder="Search..."
              style={{ width: 180 }}
            />

            <Button
              style={{
                height: 27,
                margin: 10,
                borderColor: "#d3e3ff",
                borderRadius: "4px",
                backgroundColor: "#f3f4fa",
              }}
              icon={<FilterOutlined style={{ color: "#377dff" }} />}
              onClick={handleButtonClick}
            />
            <Button
              onClick={handleSearchClick}
              style={{ height: 27, margin: 0 }}
              icon={
                <ReloadOutlined
                  style={{ color: "rgb(61, 153, 37)", fontSize: "14px" }}
                />
              }
            />
          </Col>
          {/* </Form.Item> */}
        </Row>

        {showInput && (
          <Row
            align={"middle"}
            justify={"space-between"}
            gutter={[15, 15]}
            style={{
              backgroundColor: "rgba(247, 248, 255, 0.5)",
              marginLeft: " 9.5px;",
              marginRight: "36.5px",
              borderRadius: 4,
            }}
          >
            <Col span={22} md={22}>
              {/* <Form.Item name="tstatus"> */}
              <TreeSelect
                className="issueReport-custom-select mr-3"
                placeholder="Select status"
                treeData={[
                  { title: statusconvertor(1), value: 1 },
                  { title: statusconvertor(2), value: 2 },
                  { title: statusconvertor(3), value: 3 },
                  { title: statusconvertor(7), value: 7 },
                  { title: statusconvertor(4), value: 4 },
                  { title: statusconvertor(5), value: 5 },
                  { title: statusconvertor(6), value: 6 },
                ]}
                defaultValue={[
                  { title: statusconvertor(1), value: 1 },
                  { title: statusconvertor(2), value: 2 },
                  { title: statusconvertor(3), value: 3 },
                  { title: statusconvertor(7), value: 7 },
                  { title: statusconvertor(4), value: 4 },
                  { title: statusconvertor(5), value: 5 },
                ]}
                onChange={(value: any) => {
                  setFormData((prev: any) => ({
                    ...prev,
                    status: value.map((item: any) => item),
                  }));
                }}
                treeCheckable
                maxTagCount={1}
                style={{ width: "180px" }}
                showSearch
                onSearch={onSearch}
                treeNodeFilterProp="title"
                // suffixIcon={
                //   <SearchOutlined
                //     className="mb-1 mr-1"
                //     onClick={handleSearchClick}
                //     style={{
                //       color: "rgba(0, 0, 0, 0.45)",
                //       fontSize: "16px",
                //       cursor: "pointer",
                //     }}
                //   />
                // }
              />
              {/* </Form.Item> */}

              {/* <Form.Item> */}
              <Select
                className="issueReport-custom-select mr-3"
                placeholder="Select Assignee..."
                style={{ width: 180 }}
                onChange={(value: any) => {
                  setFormData((prev: any) => ({
                    ...prev,
                    userFilter: {
                      userType: value,
                      id: prev?.userFilter?.id,
                    },
                  }));
                }}
                options={[
                  { label: "All", value: userType.All },
                  { label: "Assignee", value: userType.Assignee },
                  { label: "AssignedBy", value: userType.AssignedBy },
                  { label: "Reporter", value: userType.Reporter },
                ]}
                showSearch
                optionFilterProp="label"
                onSearch={onSearch}
                // suffixIcon={
                //   <SearchOutlined
                //     className="mb-1 mr-1"
                //     onClick={handleSearchClick}
                //     style={{
                //       color: "rgba(0, 0, 0, 0.45)",
                //       fontSize: "16px",
                //       cursor: "pointer",
                //     }}
                //   />
                // }
              />
              {/* </Form.Item> */}

              <Select
                className="issueReport-custom-select mr-3"
                style={{ width: 180 }}
                placeholder="Select User..."
                options={reporterOptions}
                loading={isUserPending}
                searchValue={searchFilter?.user?.search}
                mode="multiple"
                // onChange={(value) => {
                //   setAssigneeselect(value);
                // }}
                
                maxTagCount={1}
                labelInValue
                allowClear
                showSearch
                onSearch={(value) => {
                  setSearchFilter((pre: any) => ({
                    ...pre,
                    user: {
                      search: value,
                    },
                  }));
                }}
                optionFilterProp="label"
                // suffixIcon={
                //   <SearchOutlined
                //     className="mb-1 mr-4"
                //     onClick={handleSearchClick}
                //     style={{
                //       color: "rgba(0, 0, 0, 0.45)",
                //       fontSize: "16px",
                //       cursor: "pointer",
                //     }}
                //   />
                // }
              />

              <Select
                defaultValue={{ label: "All", value: -1 }}
                className="issueReport-custom-select"
                style={{ width: 180 }}
                onChange={(item: any) => {
                  setFormData((prev: any) => ({ ...prev, priority: item }));
                }}
                options={[
                  { label: "All", value: -1 },
                  { label: "High", value: 2 },
                  { label: "Medium", value: 1 },
                  { label: "Low", value: 0 },
                ]}
                showSearch
                optionFilterProp="label"
                onSearch={onSearch}
                // suffixIcon={
                //   <SearchOutlined
                //     className="mb-1"
                //     onClick={handleSearchClick}
                //     style={{
                //       color: "rgba(0, 0, 0, 0.45)",
                //       fontSize: "16px",
                //       cursor: "pointer",
                //     }}
                //   />
                // }
              />
            </Col>
            <Col>
              <Button
                type="text"
                style={{
                  backgroundColor: "#eef3fa",
                  borderRadius: "50%",
                  height: "25px",
                  width: "25px",
                  margin: 3,
                }}
                onClick={handleSearchClick}
                icon={
                  <ReloadOutlined
                    style={{ color: "rgb(211, 18, 18)", fontSize: "13px" }}
                  />
                }
              />

              <Button
                style={{
                  backgroundColor: "#eef3fa",
                  borderRadius: "50%",
                  height: "25px",
                  width: "25px",
                  margin: 5,
                }}
                // shape="circle"
                type="text"
                icon={<CloseOutlined className="CloseOutlinedIcon" />}
                onClick={handleCloseClick}
              />
            </Col>
          </Row>
        )}
      </Form>
    </ConfigProvider>
  );
};

export default IssueReportFilter;
